var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sale-page-table-item" }, [
    _vm.title
      ? _c("span", { staticClass: "sale-page-table-item__title" }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: [
          "sale-page-table-item__text",
          { "sale-page-table-item__text--scaled": _vm.scaledText }
        ]
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4e018d8a", { render: render, staticRenderFns: staticRenderFns })
  }
}