var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "auth-link",
      class: { "auth-link--authorized": _vm.isAuthorized }
    },
    [
      !_vm.isAuthorized
        ? _c(
            "a",
            { staticClass: "auth-link__link", attrs: { href: "/auth" } },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "16",
                    height: "16",
                    viewBox: "0 0 24 24",
                    fill: "none"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M12.75 3C12.3358 3 12 3.33579 12 3.75C12 4.16421 12.3358 4.5 12.75 4.5L18 4.5V19.5H12.75C12.3358 19.5 12 19.8358 12 20.25C12 20.6642 12.3358 21 12.75 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V4.5C19.5 4.10218 19.342 3.72065 19.0607 3.43934C18.7794 3.15804 18.3978 3 18 3H12.75ZM8.28334 7.5321C8.57627 7.23925 9.05114 7.23931 9.344 7.53224L13.2805 11.4697C13.5733 11.7626 13.5733 12.2374 13.2805 12.5303L9.344 16.4678C9.05114 16.7607 8.57627 16.7608 8.28334 16.4679C7.99041 16.175 7.99034 15.7002 8.2832 15.4072L10.9397 12.75H2.25C1.83579 12.75 1.5 12.4142 1.5 12C1.5 11.5858 1.83579 11.25 2.25 11.25H10.9397L8.2832 8.59276C7.99034 8.29983 7.99041 7.82496 8.28334 7.5321Z",
                      fill: "currentColor"
                    }
                  })
                ]
              ),
              _vm._v("\n        " + _vm._s(_vm.TEXT.login) + "\n    ")
            ]
          )
        : _c(
            "span",
            { staticClass: "auth-link__link", on: { click: _vm.onLogout } },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "16",
                    height: "16",
                    viewBox: "0 0 24 24",
                    fill: "none"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M4.5 3C4.10217 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10217 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72065 20.842 4.10218 21 4.5 21H9.75C10.1642 21 10.5 20.6642 10.5 20.25C10.5 19.8358 10.1642 19.5 9.75 19.5H4.5L4.5 4.5L9.75 4.5C10.1642 4.5 10.5 4.16421 10.5 3.75C10.5 3.33579 10.1642 3 9.75 3H4.5ZM15.7833 7.5321C16.0763 7.23925 16.5511 7.23931 16.844 7.53224L20.7805 11.4697C21.0733 11.7626 21.0733 12.2374 20.7805 12.5303L16.844 16.4678C16.5511 16.7607 16.0763 16.7608 15.7833 16.4679C15.4904 16.175 15.4903 15.7002 15.7832 15.4072L18.4397 12.75H9.75C9.33579 12.75 9 12.4142 9 12C9 11.5858 9.33579 11.25 9.75 11.25H18.4397L15.7832 8.59276C15.4903 8.29983 15.4904 7.82496 15.7833 7.5321Z",
                      fill: "currentColor"
                    }
                  })
                ]
              ),
              _vm._v("\n        " + _vm._s(_vm.TEXT.logout) + "\n    ")
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6d526e51", { render: render, staticRenderFns: staticRenderFns })
  }
}