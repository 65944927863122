<template>
    <div class="form">
        <form @submit.prevent="onSubmit">
            <div class="form__fields">
                <ZInput
                    class="form__field"
                    name="email"
                    :placeholder="TEXT.fields.email"
                    type="email"
                    ref="email"
                    required
                    v-model="formData.email"
                    :show-required-star="false"
                />
                <ZInput
                    class="form__field"
                    name="password"
                    :placeholder="TEXT.fields.password"
                    type="password"
                    ref="password"
                    required
                    :min-length="8"
                    v-model="formData.password"
                    :show-required-star="false"
                />
            </div>
            <p class="form__error" v-if="errorMessage" v-html="errorMessage"></p>
            <div class="form__footer">
                <ZButton :loading="isLoading">
                    <span v-html="TEXT.button.login" />
                </ZButton>
            </div>
        </form>

    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import ZButton from '../../app/ZButton'
import ZInput from '../../app/ZInput'
import { sendAuthForm as sendForm } from '../api/auth'
import buildFormData from '../utils/buildFormData'
import { TEXT, RESPONSE_STATUS } from './const'

const REQUIRED_FIELDS = ['email', 'password']
const USER_PROFILE_LINK = '/personal-area/'


export default {
    name: 'auth-form',
    components: {
        ZButton,
        ZInput
    },
    data () {
        return {
            TEXT,
            RESPONSE_STATUS,
            formData: {
                email: '',
                password: ''
            },
            errorMessage: '',
            isLoading: false
        }
    },
    methods: {
        onSubmit: debounce(500, async function (event) {
            this.errorMessage = '';
            const isValid = this.validate()

            if (!isValid) return
            const formData = buildFormData(this.formData)

            this.isLoading = true
            const response = await sendForm(formData)
            this.isLoading = false

            if (response.status === RESPONSE_STATUS.error) {
                this.errorMessage = response.message
                return
            }
            if (response.status === RESPONSE_STATUS.success) {
                window.location = USER_PROFILE_LINK
            }
        }),
        validate () {
            let isValid = true

            REQUIRED_FIELDS.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })

            return isValid
        }
    }
}
</script>

<style lang="scss" scoped>
@import './form.scss';
</style>