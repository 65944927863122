<template>
    <div class="filter-illiquid">
        <div class="filter-illiquid__container">
            <z-input
                class="filter-illiquid__search"
                :title="TEXT.search"
                :placeholder="TEXT.searchPlaceholder"
                v-model="params.q"
                @input="onInput"
                name="q"
            />
            <z-select
                :title="TEXT.type"
                :data="data.type"
                :placeholder="TEXT.all"
                v-model="params.type"
                @change="onChange"
                name="type"
            />

            <z-select
                :title="TEXT.subType"
                :data="data.subType"
                :placeholder="TEXT.all"
                v-model="params.subType"
                @change="onChange"
                name="subType"
            />

            <z-select
                :title="TEXT.images"
                :data="imagesSelect"
                :placeholder="TEXT.imagesPlaceholder"
                v-model="params.images"
                name="subType"
                class="filter-illiquid__image-type"
                @change="onChange"
            />
        </div>

        <button
            class="filter-illiquid__clear"
            v-if="haveParams"
            @click="clear"
        >
            {{ TEXT.clear }}
        </button>

    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import clearEmptyParams from '../../utils/clearEmptyParams'
import ZSelect from '../../../app/ZSelect/ZSelect.vue'
import ZInput from '../../../app/ZInput/ZInput.vue'

const TEXT = {
    all: 'Все',
    type: 'Наименование группы материалов',
    subType: 'Наименование подгруппы материалов',
    search: 'Поиск',
    searchPlaceholder: 'Введите ключевое слово',
    clear: 'Сбросить фильтры',
    images: 'Наличие фотографии',
    imagesPlaceholder: 'Неважно'
}

export default {
    name: 'filter-illiquid',
    props: {
        data: {
            type: Object
        }
    },
    components: {
        ZSelect,
        ZInput
    },
    data () {
        return {
            TEXT,
            params: {
                q: '',
                type: '',
                subType: '',
                images: '',
            },
            imagesSelect: [
              {
                disabled: false,
                id: '',
                selected: false,
                text: 'Неважно',
                xmlId: 'Неважно'
              },
              {
                disabled: false,
                id: 'Y',
                selected: false,
                text: 'Только с фото',
                xmlId: 'Только с фото'
              },
              {
                disabled: false,
                id: 'N',
                selected: false,
                text: 'Только без фото',
                xmlId: 'Только без фото'
              },
            ]
        }
    },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.params = {
      ...this.params,
      q: urlParams.get('q') || '',
    }
  },
    methods: {
        onInput: debounce(500, function () {
            this.send()
        }),
        onChange () {
            this.send()
        },
        send () {
            this.$emit('change', this.params)
        },
        clear () {
            this.params.q = ''
            this.params.type = ''
            this.params.subType = ''
            this.params.images = ''
            this.send()
        }
    },
    computed: {
        haveParams () {
            return Object.keys(clearEmptyParams(this.params)).length
        }
    },

}
</script>

<style lang="scss">
.filter-illiquid {
    position: relative;
    padding-bottom: 48px;

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;

        @include breakpoint (v-tablet) {
            grid-template-columns: 1fr;
            gap: 16px;
        }
    }

    &__search {
        grid-column: 1 / 4;

        @include breakpoint (v-tablet) {
            grid-column: 1 / 2;
        }
    }

    &__clear {
        position: absolute;
        right: 0;
        bottom: 0;
        border: 1px solid $token-colors-black-60;
        border-radius: 40px;
        padding: 6px 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 1;
        color: $token-colors-black-60;
        background-color: $token-colors-white;
        box-shadow: -16px 0 0 rgba(255, 255, 255, 1);

        @include breakpoint(v-tablet) {
            padding: 0;
            border: none;
        }

        @include breakpoint (mobile) {
            bottom: 16px;
        }
    }

    &__image-type .z-select.is-filled .z-select__placeholder {
      display: none;
    }

}
</style>
