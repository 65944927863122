var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "z-select-wrapper" }, [
    _c("div", { staticClass: "z-select__title-container" }, [
      _vm.title
        ? _c("p", {
            staticClass: "z-select__title",
            domProps: { innerHTML: _vm._s(_vm.title) }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: [
          "z-select",
          {
            "is-opened": _vm.opened,
            "is-errored": _vm.isValid === false,
            "is-filled": _vm.selected.text && !_vm.selected.default,
            "is-valid": _vm.isValid
          }
        ]
      },
      [
        _c(
          "div",
          {
            staticClass: "z-select__container",
            attrs: { tabindex: "0" },
            on: {
              click: function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                _vm.opened = !_vm.opened
              }
            }
          },
          [
            _vm.placeholder
              ? _c("span", {
                  staticClass: "z-select__placeholder",
                  domProps: { innerHTML: _vm._s(_vm.placeholder) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.selected.text.length
              ? _c("span", {
                  staticClass: "z-select__selected",
                  domProps: { innerHTML: _vm._s(_vm.selected.text) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.selected.text.length && _vm.clearable
              ? _c("span", {
                  staticClass: "z-select__clear",
                  on: {
                    click: function($event) {
                      if ($event.target !== $event.currentTarget) {
                        return null
                      }
                      return _vm.removeSelected.apply(null, arguments)
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "z-select__arrow" })
          ]
        ),
        _vm._v(" "),
        _vm.opened
          ? _c(
              "div",
              { staticClass: "z-select__dropdown" },
              [
                _vm.withSearch
                  ? _c(
                      "div",
                      { staticClass: "z-select__input-wrapper" },
                      [
                        _c("z-input", {
                          attrs: { name: "search", "icon-name": "magnifier" },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.groupedOptions, function(group, groupIndex) {
                  return _c(
                    "ul",
                    {
                      key: groupIndex,
                      staticClass: "z-select__options ul--clear"
                    },
                    [
                      _c("li", [
                        group.name
                          ? _c("span", {
                              staticClass: "z-select__caption",
                              domProps: { innerHTML: _vm._s(group.name) }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        group.items && group.items.length
                          ? _c(
                              "ul",
                              { staticClass: "ul--clear" },
                              _vm._l(group.items, function(option) {
                                return _c(
                                  "li",
                                  {
                                    key: option.id,
                                    staticClass: "z-select__option",
                                    class: _vm.buildClass(option),
                                    on: {
                                      click: function($event) {
                                        return _vm.changeSelected(option)
                                      }
                                    }
                                  },
                                  [_c("p", [_vm._v(_vm._s(option.text))])]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                })
              ],
              2
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _vm.required && _vm.error && !_vm.selected.id.length
      ? _c("span", { class: ["z-select__error", _vm.errorClass] }, [
          _vm._v(
            "\n        " + _vm._s(_vm.text.error[_vm.$root.lang]) + "\n    "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-36974d16", { render: render, staticRenderFns: staticRenderFns })
  }
}