export default function buildFormData (obj) {
    const formData = new FormData()

    for (const key in obj) {
        if (obj[key] instanceof FileList || Array.isArray(obj[key])) {
            Array.from(obj[key]).forEach(item => formData.append(`${key}[]`, item))
        } else {
            formData.append(key, obj[key])
        }
    }

    return formData
}