var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.length
    ? _c("div", { staticClass: "z-checkbox-group" }, [
        _c(
          "div",
          { staticClass: "z-checkbox-group__list" },
          _vm._l(_vm.data, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                class: [
                  "z-checkbox z-checkbox-group__item",
                  { "is-error": _vm.error }
                ]
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "z-checkbox__label",
                    class: { "z-checkbox__label--disabled": item.disabled }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.groupValue,
                          expression: "groupValue"
                        }
                      ],
                      staticClass: "z-checkbox__input",
                      attrs: {
                        type: "checkbox",
                        disabled:
                          item.disabled === "undefined" ? false : item.disabled
                      },
                      domProps: {
                        value: item.value,
                        checked: Array.isArray(_vm.groupValue)
                          ? _vm._i(_vm.groupValue, item.value) > -1
                          : _vm.groupValue
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.groupValue,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = item.value,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.groupValue = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.groupValue = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.groupValue = $$c
                            }
                          },
                          _vm.onChange
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "z-checkbox__box" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "z-icon z-fill z-top",
                          staticStyle: { width: "22px", height: "22px" },
                          attrs: { version: "1.1", viewBox: "0 0 24 24" }
                        },
                        [
                          _c("path", {
                            attrs: {
                              pid: "0",
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M20.424 6.425a.6.6 0 10-.848-.849L9 16.152l-4.576-4.576a.6.6 0 00-.848.849l5 5a.6.6 0 00.848 0l11-11z",
                              fill: "#0077C8"
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm._t("text")
                  ],
                  2
                )
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.error
          ? _c("span", {
              class: ["z-checkbox-group__error", _vm.errorClass],
              domProps: { innerHTML: _vm._s(_vm.error) }
            })
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a26b0efa", { render: render, staticRenderFns: staticRenderFns })
  }
}