<template>
    <div class="used-products-card">
        <h4 class="used-products-card__title" v-html="title"></h4>

        <div class="used-products-card__info">
            <div v-if="roks" class="used-products-card__info-item used-products-card__info-item--roks">
                <p class="used-products-card__subtitle">РОКС</p>
                <p class="used-products-card__info-value" v-html="roks"></p>
            </div>

            <div v-if="area" class="used-products-card__info-item used-products-card__info-item--area">
                <p class="used-products-card__subtitle">Площадка</p>
                <p class="used-products-card__info-value" v-html="area"></p>
            </div>

            <div v-if="unit" class="used-products-card__info-item used-products-card__info-item--unit">
                <p class="used-products-card__subtitle">Ед. измерения</p>
                <p class="used-products-card__info-value" v-html="unit"></p>
            </div>

            <div v-if="price" class="used-products-card__info-item used-products-card__info-item--price">
                <p class="used-products-card__subtitle">Цена без НДС</p>
                <p class="used-products-card__info-value">
                    <span class="used-products-card__info-inner" v-html="price"></span>&nbsp;руб.
                </p>
            </div>
        </div>

        <div class="used-products-card__body">
            <p class="used-products-card__subtitle">Тех. характеристика</p>
            <p class="used-products-card__description" v-html="description"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'used-products-card',
    props: {
        title: String,
        roks: String,
        area: String,
        unit: String,
        price: String,
        description: String
    }
}
</script>

<style lang="scss" src="./used-products-card.scss"></style>