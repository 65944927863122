var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form" }, [
    !_vm.isSended
      ? _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "form__fields" },
              [
                _c("ZInput", {
                  ref: "password",
                  staticClass: "form__field",
                  attrs: {
                    name: "password",
                    placeholder: _vm.TEXT.fields.password,
                    type: "password",
                    required: "",
                    "min-length": 8
                  },
                  model: {
                    value: _vm.formData.password,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "password", $$v)
                    },
                    expression: "formData.password"
                  }
                }),
                _vm._v(" "),
                _c("ZInput", {
                  ref: "password_repeat",
                  staticClass: "form__field",
                  attrs: {
                    name: "password_repeat",
                    placeholder: _vm.TEXT.fields.passwordRepeat,
                    type: "password",
                    required: ""
                  },
                  model: {
                    value: _vm.formData.password_repeat,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "password_repeat", $$v)
                    },
                    expression: "formData.password_repeat"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("p", {
              staticClass: "form__field-description",
              domProps: {
                innerHTML: _vm._s(_vm.TEXT.description.passwordRequirements)
              }
            }),
            _vm._v(" "),
            _vm.errorMessage
              ? _c("p", {
                  staticClass: "form__error",
                  domProps: { innerHTML: _vm._s(_vm.errorMessage) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form__footer" },
              [
                _c("ZButton", { attrs: { loading: _vm.isLoading } }, [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.TEXT.button.send) }
                  })
                ])
              ],
              1
            )
          ]
        )
      : _c("div", { staticClass: "form__success" }, [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.TEXT.successMessage.passwordChanged)
            }
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "link cursor-pointer",
            domProps: { innerHTML: _vm._s(_vm.TEXT.links.login) },
            on: { click: _vm.changeState }
          })
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2a567f1e", { render: render, staticRenderFns: staticRenderFns })
  }
}