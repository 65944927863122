<template>
  <div class="user-profile">
    <section v-if="data && data.documents && data.documents.length" class="user-profile__info">
      <div>
        <h2 v-html="TEXT.documents.title"/>

        <div class="user-profile__documents">
          <div
              class="document"
              v-for="(item, index) in data.documents"
              :key="index"
          >
            <h3 class="u-bottom-margin--2xs-important" v-if="item.type && item.type.value" v-html="item.type.value"/>
            <h4 class="document_name u-bottom-margin--2xs-important" v-html="item.name"/>
            <p class="document__description" v-if="item.text && item.text.value" v-html="item.text.value"/>
            <div class="file-list" v-if="item.file && item.file.link">
              <ul class="file-list__group">
                <li :class="`file-list__item file-list__item--default file-list__item--${item.file.type}`">
                  <a
                      :href="item.file.link"
                      class="link file-list__link"
                      target="_blank"
                  >{{ TEXT.download }}</a>
                  <span class="file-list__size u-uppercase">{{ item.file.type }}, {{ item.file.size }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="user-profile__qr">
        <p class="u-bottom-margin--2xs">QR код для оплаты</p>
        <img src="/local/templates/nornik/img/qr-kolagmk.png" alt="">
      </div>
    </section>

    <section v-if="data && data.contacts && data.contacts.length">
      <Contacts :data="data.contacts"/>
    </section>

    <section>
      <h2 v-html="TEXT.commecialOffer"></h2>
      <CommercialOfferForm v-if="auth && auth.authorized"/>
      <p v-else>Для отправки коммерческого предложения, необходимо <a class="link" href="/auth">авторизоваться</a>.</p>
    </section>
  </div>
</template>

<script>
import CommercialOfferForm from '../CommercialOfferForm/CommercialOfferForm.vue'
import Contacts from '../Contacts/Contacts.vue'
import {TEXT} from './const'

export default {
  name: 'user-profile',
  components: {
    CommercialOfferForm,
    Contacts
  },
  data() {
    return {
      TEXT,
      data: this.$root?.app?.components?.['lk-partner'],
      auth: this.$root?.app?.components?.auth
    }
  }
}
</script>

<style lang="scss" scoped>
.user-profile {
  display: flex;
  flex-direction: column;
  gap: 96px;

  @include breakpoint(tablet) {
    gap: 64px;
  }

  @include breakpoint(mobile) {
    gap: 48px;
  }

  &__documents {
    display: flex;
    flex-direction: column;
    gap: 48px;
    line-height: 1.2;

    @include breakpoint(tablet) {
      gap: 32px;
    }

    @include breakpoint(mobile) {
      gap: 24px;
    }
  }

  &__info{
    display: flex;
    justify-content: space-between;
    gap: 48px;
    @include breakpoint(mobile){
      gap: 16px;
    }
  }

  &__qr{
    width: 200px;
    height: 200px;
    @include breakpoint(v-tablet) {
      width: 150px;
      height: 150px;
    }
    @include breakpoint(mobile){
      width: 100px;
      height: 100px;
    }
  }
}
</style>