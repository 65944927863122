<template>
    <div class="contacts" v-if="data && data.length">
        <h2 v-html="TEXT.title" />
        <div class="contacts__container">
            <div
                class="contact"
                v-for="(item, index) in data"
                :key="index"
            >
                <div class="contact__header">
                    <p class="contact__name" v-html="item.fio"></p>
                    <p class="contact__description" v-if="item.group && item.group.value" v-html="item.group.value" />
                </div>
                <div class="contact__container">
                    <div class="contact__row" v-if="item.phone && item.phone.value">
                        <span class="contact__prop" v-html="TEXT.phone" />
                        <span class="contact__value" v-html="item.phone.value" />
                    </div>
                    <div class="contact__row" v-if="item.email && item.email.value">
                        <span class="contact__prop" v-html="TEXT.email" />
                        <a
                            :href="`mailto:${item.email.value}`"
                            class="contact__value link"
                            v-html="item.email.value"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const TEXT = {
    title: 'Контакты',
    email: 'E-mail:',
    phone: 'Телефон:'
}

export default {
    name: 'contacts',
    props: {
        data: {
            type: Array
        }
    },
    data () {
        return {
            TEXT
        }
    }
}
</script>
<style lang="scss" scoped>
.contacts {
    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        @include breakpoint (v-tablet) {
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
        }

        @include breakpoint (mobile) {
            grid-template-columns: repeat(1, 1fr);
            gap: 8px;
        }
    }
}

.contact {
    border: 1px solid #7db0df;
    border-radius: 16px;
    padding: 24px 32px;
    background: #f6fafc;

    @include breakpoint (tablet) {
        padding: 16px 24px;
    }

    @include breakpoint (mobile) {
        padding: 16px;
    }

    &__header {
        margin-bottom: 16px;
    }

    &__name {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
        line-height: 1.2;

        @include breakpoint (mobile) {
            font-size: 18px;
        }
    }

    &__description {
        color: $token-colors-black-40;
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 4px;
        line-height: 1.2;
    }

    &__row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px 8px;
        line-height: 1.2;

        @include breakpoint (mobile) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__prop {
        min-width: 70px;
        font-size: 16px;
        color: $token-colors-black-40;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 6px;
        font-size: 16px;
    }
}
</style>