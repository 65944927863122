var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "z-textarea",
      class: {
        "is-error": _vm.error,
        "is-filled": _vm.val,
        "is-focused": _vm.focus,
        "is-resizable": _vm.resize,
        "is-disabled": _vm.disabled
      },
      attrs: { for: _vm.name }
    },
    [
      _vm.title
        ? _c("p", {
            staticClass: "z-textarea__title",
            domProps: { innerHTML: _vm._s(_vm.title) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "textarea",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.textareaValue,
                expression: "textareaValue"
              }
            ],
            attrs: {
              id: _vm.name,
              name: _vm.name,
              disabled: _vm.disabled,
              cols: "30",
              rows: "10"
            },
            domProps: { value: _vm.textareaValue },
            on: {
              blur: _vm.onBlur,
              focus: function($event) {
                _vm.focus = true
              },
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.textareaValue = $event.target.value
                },
                function($event) {
                  return _vm.onInput($event)
                }
              ],
              change: function($event) {
                return _vm.onChange($event)
              }
            }
          },
          "textarea",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _vm.placeholder && !_vm.focus && !_vm.val
        ? _c("span", {
            staticClass: "z-textarea__placeholder",
            domProps: { innerHTML: _vm._s(_vm.placeholder) }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isValid && _vm.error
        ? _c("span", { staticClass: "z-textarea__error" }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-91d3809e", { render: render, staticRenderFns: staticRenderFns })
  }
}