import Vue from 'vue';
import UsedProductsCard from './components/UsedProductsCard.vue'

const container = document.getElementById('used-products-list');
const exists = typeof(container) != 'undefined' && container != null;

if (exists) UsedProducts();

function UsedProducts() {
    new Vue({
        el: '#used-products-list',
        components: {
            UsedProductsCard
        }
    });
};