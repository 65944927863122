<template>
  <div class="file-field">
    <h3 class="u-bottom-margin--2xs-important" v-html="title"></h3>
    <div class="u-bottom-margin--s" v-if="list && list.length">
      <h5 class="u-bottom-margin--2xs" v-html="TEXT.description.upLoadedFiles"/>

      <!-- to do: updated html -->
      <div class="file-field__container">
        <ul class="z-input-file__list">
          <li
              class="z-input-file__item"
              v-for="(item, index) in list"
              :key="index"
          >
            <p class="z-input-file__item-name">
              <span>{{ item.name }}</span>
              <span @click="deleteFile(item)" class="z-input-file__item-clear"></span>
            </p>
            <p class="z-input-file__item-info">{{ item.type }} ({{ item.size }})</p>
          </li>
        </ul>
      </div>
    </div>

    <z-input-file
        :description="TEXT.description.acceptedFile"
        :required-info="requiredInfo"
        name="charter"
        ref="charter"
        @change="fileOnChange"
        accept=".pdf, .png, .jpg, .jpeg, .xlsx, .docx"
        multiple
        :max-size="2097152"
    />

    <span
        class="file-field__error"
        v-html="error"
        v-if="error"
    ></span>
  </div>
</template>

<script>
import ZInputFile from '../../app/ZInputFile'
import {TEXT} from './const'

export default {
  name: 'file-field',
  components: {
    ZInputFile,
  },
  props: {
    title: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    data: {
      type: Array
    },
    required: {
      type: Boolean,
      default: true
    },
    requiredInfo: {
      type: String,
    }
  },
  data() {
    return {
      TEXT,
      list: [],
      deleted: [],
      newFiles: [],
      error: '',
      text: {
        required: {
          ru: 'Поле обязательно для заполнения',
          en: 'Required field',
        },
      }
    }
  },
  methods: {
    send() {
      this.$emit('change', {
        name: this.name,
        new: this.newFiles,
        deleted: this.deleted
      })
    },
    fileOnChange(data) {
      this.newFiles = data
      this.validate()
      this.send()
    },
    deleteFile(item) {
      this.deleted.push(item.id)
      const index = this.list.indexOf(item)
      this.list.splice(index, 1)
      this.validate()
      this.send()
    },
    validate() {
      if (!this.list?.length && !this.newFiles?.length && this.required) {
        this.error = localize(this.text.required)
      } else {
        this.error = ''
      }
    }
  },
  created() {
    this.list = this.data
  }
}
</script>

<style lang="scss" scoped>
.file-field {
  &__container {
    border-radius: 16px;
    background-color: #F3F4F8;
    padding: 32px;

    ul {
      margin: 0 !important;
    }
  }

  &__error {
    font-size: 12px;
    color: $token-colors-form-border-color-error;
    position: relative;
    display: block;
    line-height: 1.2;
    margin-top: 16px;
  }
}
</style>