<template>
    <div class="commercial-offer-form">
        <form class="form" @submit.prevent="onSubmit" v-if="!isSended">
            <div class="form__fields">
                <FileField
                    name="charter"
                    ref="charter"
                    :data="data.charter"
                    @change="onFileChange"
                    :title="TEXT.fields.charter"
                    :required="false"
                    :required-info="TEXT.requiredInfo.legalEntitry"
                />

                <FileField
                    name="inn"
                    ref="inn"
                    :data="data.inn"
                    @change="onFileChange"
                    :title="TEXT.fields.inn"
                    :required-info="TEXT.requiredInfo.both"
                />

                <FileField
                    name="egrul"
                    ref="egrul"
                    :data="data.egrul"
                    @change="onFileChange"
                    :title="TEXT.fields.egrul"
                    :required="false"
                    :required-info="TEXT.requiredInfo.legalEntitry"
                />

                <FileField
                    name="order-appointment-director"
                    ref="order-appointment-director"
                    :data="data['order-appointment-director']"
                    @change="onFileChange"
                    :title="TEXT.fields['order-appointment-director']"
                    :required="false"
                    :required-info="TEXT.requiredInfo.legalEntitry"
                />

                <FileField
                    name="balance"
                    ref="balance"
                    :data="data.balance"
                    @change="onFileChange"
                    :title="TEXT.fields.balance"
                    :required="false"
                    :required-info="TEXT.requiredInfo.legalEntitry"
                />

                <FileField
                    name="certificate-absence-debt"
                    ref="certificate-absence-debt"
                    :data="data['certificate-absence-debt']"
                    @change="onFileChange"
                    :title="TEXT.fields['certificate-absence-debt']"
                    :required="false"
                    :required-info="TEXT.requiredInfo.legalEntitry"
                />

                <FileField
                    :required="false"
                    name="additional-docs"
                    ref="additional-docs"
                    :data="data['additional-docs']"
                    @change="onFileChange"
                    :title="TEXT.fields['additional-docs']"
                />

                <FileField
                    name="commercial-offer"
                    ref="commercial-offer"
                    :data="data['commercial-offer']"
                    @change="onFileChange"
                    :title="TEXT.fields['commercial-offer']"
                    :required-info="TEXT.requiredInfo.both"
                />

                <div>
                    <h3 class="u-bottom-margin--3xs-important" v-html="TEXT.fields.message"></h3>
                    <z-textarea
                        v-model="formData.message"
                        name="msg"
                        ref="msg"
                    ></z-textarea>
                </div>
            </div>
            <p class="form__error" v-if="errorMessage" v-html="errorMessage"></p>
            <div class="form__footer">
                <ZButton :loading="isLoading">
                    <span v-html="TEXT.button.send" />
                </ZButton>
                <ZButton
                  tag="a"
                  kind="text"
                  class="form__logout"
                  @click="onLogoutClick"
                >
                    <span v-html="TEXT.button.logout" />
                </ZButton>
            </div>
        </form>
        <div class="form__success" v-else>
            <p v-html="TEXT.successMessage" />
        </div>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { TEXT, RESPONSE_STATUS } from './const'
import ZButton from '../../app/ZButton'
import ZTextarea from '../../app/ZTextarea'
import FileField from './FileField.vue'
import { sendCommercialForm as sendForm } from '../api/user-profile'
import buildFormData from '../utils/buildFormData'
import {logout} from "../api/auth";

const REQUIRED_FIELDS = ['charter', 'inn', 'egrul', 'order-appointment-director', 'balance', 'certificate-absence-debt', 'commercial-offer']

export default {
    name: 'commercial-offer-form',
    components: {
        ZButton,
        ZTextarea,
        FileField
    },
    data () {
        return {
            TEXT,
            isLoading: false,
            isSended: false,
            errorMessage: '',
            data: this.$root.app.components['lk-form'] || {},
            formData: {
                charter: [],
                'charter-new': [],
                inn: [],
                'inn-new': [],
                egrul: [],
                'egrul-new': [],
                'order-appointment-director': [],
                'order-appointment-director-new': [],
                balance: [],
                'balance-new': [],
                'certificate-absence-debt': [],
                'certificate-absence-debt-new': [],
                'additional-docs': [],
                'additional-docs-new': [],
                'commercial-offer': [],
                'commercial-offer-new': [],
                message: '',
            }
        }
    },
    methods: {
      async onLogoutClick(){
        await logout();
        location.reload();
      },
        onSubmit: debounce(500, async function (event) {
            this.errorMessage = ''
            const isValid = this.validate()

            if (!isValid) return

            const formData = buildFormData(this.formData)
            this.isLoading = true

            try {
                const response = await sendForm(formData)

                if (response.success === RESPONSE_STATUS.error) {
                    this.errorMessage = response.message
                    return
                }

                if (response.success === RESPONSE_STATUS.success) {
                    this.isSended = true
                }
            } catch (e) {
                this.isLoading = false
                this.errorMessage = TEXT.errorMessage
            }
        }),
        onFileChange (data) {
            this.formData[`${data.name}-new`] = data.new
            this.formData[`${data.name}`] = data.deleted
        },
        validate () {
            let isValid = true

            REQUIRED_FIELDS.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })

            return isValid
        }
    }
}
</script>

<style lang="scss">
.form {
    &__fields {
        display: flex;
        flex-direction: column;
        gap: 48px;
        margin-bottom: 32px;

        @include breakpoint (tablet) {
            gap: 32px;
        }

        @include breakpoint (mobile) {
            gap: 24px;
        }
    }

    &__success {
        font-size: 18px;
        font-weight: 600;
    }

    &__error {
        font-size: 14px;
        color: $token-colors-form-border-color-error;
        margin-bottom: 16px;
    }
  &__footer{
    display: flex;
    justify-content: space-between;
  }

  &__logout.z-button{
    color: red;
    text-decoration: underline;
  }
}


.commercial-offer-form {
}
</style>