var disposed = false
function injectStyle (context) {
  if (disposed) return
  require("!!vue-style-loader!css-loader!../../../../node_modules/vue-loader/lib/style-compiler/index?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-1379ebd5\",\"scoped\":true,\"sourceMap\":true}!sass-loader!sass-resources-loader?{\"sourceMap\":true,\"resources\":[\"/pub/www/kolagmknornik/releases/20250331103004Z/frontend/styles/vars/colors/_colors.scss\",\"/pub/www/kolagmknornik/releases/20250331103004Z/frontend/styles/vars/_vars.scss\",\"/pub/www/kolagmknornik/releases/20250331103004Z/frontend/styles/base/mixins/_a-mixins.scss\",\"/pub/www/kolagmknornik/releases/20250331103004Z/frontend/styles/vars/tokens/_colors.scss\",\"/pub/www/kolagmknornik/releases/20250331103004Z/frontend/styles/vars/tokens/_grids.scss\",\"/pub/www/kolagmknornik/releases/20250331103004Z/frontend/styles/vars/tokens/_spacers.scss\",\"/pub/www/kolagmknornik/releases/20250331103004Z/frontend/styles/vars/tokens/_spacers-mixin.scss\",\"/pub/www/kolagmknornik/releases/20250331103004Z/frontend/styles/vars/tokens/_typography.scss\",\"/pub/www/kolagmknornik/releases/20250331103004Z/frontend/styles/vars/tokens/_typography-mixin.scss\"]}!../../../../node_modules/vue-loader/lib/selector?type=styles&index=0!./UserProfile.vue")
}
/* script */
export * from "!!babel-loader?{\"cacheDirectory\":\"cache\",\"presets\":[\"@babel/env\"]}!../../../../node_modules/vue-loader/lib/selector?type=script&index=0!./UserProfile.vue"
import __vue_script__ from "!!babel-loader?{\"cacheDirectory\":\"cache\",\"presets\":[\"@babel/env\"]}!../../../../node_modules/vue-loader/lib/selector?type=script&index=0!./UserProfile.vue"
/* template */
import {render as __vue_render__, staticRenderFns as __vue_static_render_fns__} from "!!../../../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-1379ebd5\",\"hasScoped\":true,\"optionsId\":\"0\",\"buble\":{\"transforms\":{}}}!../../../../node_modules/vue-loader/lib/selector?type=template&index=0!./UserProfile.vue"
/* template functional */
var __vue_template_functional__ = false
/* styles */
var __vue_styles__ = injectStyle
/* scopeId */
var __vue_scopeId__ = "data-v-1379ebd5"
/* moduleIdentifier (server only) */
var __vue_module_identifier__ = null
import normalizeComponent from "!../../../../node_modules/vue-loader/lib/runtime/component-normalizer"
var Component = normalizeComponent(
  __vue_script__,
  __vue_render__,
  __vue_static_render_fns__,
  __vue_template_functional__,
  __vue_styles__,
  __vue_scopeId__,
  __vue_module_identifier__
)
Component.options.__file = "frontend/components/non-core-assets/UserProfile/UserProfile.vue"

/* hot reload */
if (module.hot) {(function () {
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), false)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-1379ebd5", Component.options)
  } else {
    hotAPI.reload("data-v-1379ebd5", Component.options)
  }
  module.hot.dispose(function (data) {
    disposed = true
  })
})()}

export default Component.exports
