function sanitizeValue (text) {
    return text
        // htmlspecialchars
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
        // addslashes
        .replace(/\\/g, '\\\\')
        // eslint-disable-next-line
        .replace(/\u0008/g, '\\b')
        .replace(/\t/g, '\\t')
        .replace(/\n/g, '\\n')
        .replace(/\f/g, '\\f')
        .replace(/\r/g, '\\r')
        .replace(/'/g, '\\\'')
        .replace(/"/g, '\\"')
}

export default function parseGetParams (url = window.location) {
    let params = {}

    new URL(url).searchParams.forEach((val, key) => {
        if (key.includes('[]')) {
            if (!params[key.replace('[]', '')]) params[key.replace('[]', '')] = []
            if (!params[key.replace('[]', '')].includes(val)) params[key.replace('[]', '')].push(sanitizeValue(val))
        } else {
            params[key] = sanitizeValue(val)
        }
    })

    return params
}
