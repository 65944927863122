<template>
    <div class="z-preloader" v-html="svgData"></div>
</template>

<script>
export default {
    name: 'z-preloader',
    computed: {
        svgData () {
            return require('./images/preloader.svg')
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
