var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth" },
    [
      _vm.state === _vm.AUTH_STATE.auth
        ? [
            _c("h1", {
              staticClass: "auth__title",
              domProps: { innerHTML: _vm._s(_vm.TEXT.title.auth) }
            }),
            _vm._v(" "),
            _c("div", [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.TEXT.description.hasNotAccout)
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "link cursor-pointer",
                domProps: { innerHTML: _vm._s(_vm.TEXT.links.reg) },
                on: {
                  click: function($event) {
                    return _vm.changeState(_vm.AUTH_STATE.reg)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("AuthForm", { staticClass: "u-top-margin--m" }),
            _vm._v(" "),
            _c("div", { staticClass: "u-top-margin--s" }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.TEXT.description.forgotPassword)
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "link cursor-pointer",
                domProps: { innerHTML: _vm._s(_vm.TEXT.links.restore) },
                on: {
                  click: function($event) {
                    return _vm.changeState(_vm.AUTH_STATE.restore)
                  }
                }
              })
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.state === _vm.AUTH_STATE.restore
        ? [
            _c("h1", {
              staticClass: "auth__title",
              domProps: { innerHTML: _vm._s(_vm.TEXT.title.restore) }
            }),
            _vm._v(" "),
            _c("p", [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.TEXT.description.alredyHasAccout)
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "link cursor-pointer",
                domProps: { innerHTML: _vm._s(_vm.TEXT.links.login) },
                on: {
                  click: function($event) {
                    return _vm.changeState(_vm.AUTH_STATE.auth)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("RestoreForm")
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.state === _vm.AUTH_STATE.reg
        ? [
            _c("h1", {
              staticClass: "auth__title",
              domProps: { innerHTML: _vm._s(_vm.TEXT.title.reg) }
            }),
            _vm._v(" "),
            _c("div", [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.TEXT.description.alredyHasAccout)
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "link cursor-pointer",
                domProps: { innerHTML: _vm._s(_vm.TEXT.links.login) },
                on: {
                  click: function($event) {
                    return _vm.changeState(_vm.AUTH_STATE.auth)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("RegistrationForm", { staticClass: "u-top-margin--m" })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.state === _vm.AUTH_STATE.changePassword
        ? [
            _c("h1", {
              staticClass: "auth__title",
              domProps: { innerHTML: _vm._s(_vm.TEXT.title.changePassword) }
            }),
            _vm._v(" "),
            _c("ChangePasswordForm", {
              staticClass: "u-top-margin--m",
              on: { "change-state": _vm.changeState }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.state === _vm.AUTH_STATE.confirmEmail
        ? [
            _c("h1", {
              staticClass: "auth__title",
              domProps: { innerHTML: _vm._s(_vm.TEXT.title.auth) }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "auth__success" }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.TEXT.successMessage.emailConfirmed)
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "link cursor-pointer",
                domProps: { innerHTML: _vm._s(_vm.TEXT.links.login) },
                on: {
                  click: function($event) {
                    return _vm.changeState(_vm.AUTH_STATE.auth)
                  }
                }
              })
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4a4b0eb7", { render: render, staticRenderFns: staticRenderFns })
  }
}