var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sale-page-card__slider",
      class: "js-image-slider-" + _vm.id
    },
    [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.images, function(image) {
          return _c(
            "div",
            {
              staticClass: "swiper-slide",
              on: {
                click: function($event) {
                  return _vm.onImgClick(image.link)
                }
              }
            },
            [_c("img", { attrs: { src: image.link, alt: "" } })]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isLightboxShown
          ? _c("div", { staticClass: "sale-page-card__lightbox" }, [
              _c(
                "div",
                {
                  staticClass: "sale-page-card__lightbox-inner",
                  on: {
                    click: function($event) {
                      _vm.isLightboxShown = false
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass:
                        "sale-page-card__lightbox-close z-icon z-fill",
                      staticStyle: { width: "30px", height: "30px" },
                      attrs: { version: "1.1", viewBox: "0 0 24 24" }
                    },
                    [
                      _c("path", {
                        attrs: {
                          pid: "0",
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d:
                            "M18.424 6.425a.6.6 0 10-.848-.849L12 11.152 6.424 5.576a.6.6 0 10-.848.849L11.15 12l-5.575 5.576a.6.6 0 00.848.849L12 12.849l5.576 5.576a.6.6 0 00.848-.849L12.848 12l5.576-5.575z",
                          fill: "#ffffff"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("img", { attrs: { src: _vm.imageSrc, alt: "" } })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "sale-page-card__lightbox-button --prev",
                  on: { click: _vm.prevBtnClick }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "48",
                        height: "48",
                        viewBox: "0 0 17 16",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d:
                            "M7.77652 10.4243C7.88904 10.5368 8.04165 10.6 8.20078 10.6C8.35991 10.6 8.51252 10.5368 8.62505 10.4243L12.625 6.42429C12.8594 6.18997 12.8594 5.81008 12.625 5.57576C12.3907 5.34145 12.0108 5.34145 11.7765 5.57576L8.20078 9.1515L4.62505 5.57576C4.39073 5.34145 4.01083 5.34145 3.77652 5.57576C3.5422 5.81007 3.5422 6.18997 3.77652 6.42429L7.77652 10.4243Z",
                          fill: "#ECEEF3"
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "sale-page-card__lightbox-button --next",
                  on: { click: _vm.nextBtnClick }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "48",
                        height: "48",
                        viewBox: "0 0 17 16",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d:
                            "M7.77652 10.4243C7.88904 10.5368 8.04165 10.6 8.20078 10.6C8.35991 10.6 8.51252 10.5368 8.62505 10.4243L12.625 6.42429C12.8594 6.18997 12.8594 5.81008 12.625 5.57576C12.3907 5.34145 12.0108 5.34145 11.7765 5.57576L8.20078 9.1515L4.62505 5.57576C4.39073 5.34145 4.01083 5.34145 3.77652 5.57576C3.5422 5.81007 3.5422 6.18997 3.77652 6.42429L7.77652 10.4243Z",
                          fill: "#ECEEF3"
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sale-page-card__slider-nav" }, [
      _c("button", {
        staticClass:
          "sale-page-card__slider-button sale-page-card__slider-button-prev"
      }),
      _vm._v(" "),
      _c("button", {
        staticClass:
          "sale-page-card__slider-button sale-page-card__slider-button-next"
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1c1c6c69", { render: render, staticRenderFns: staticRenderFns })
  }
}