<template>
  <div :class="[
    'z-input-file',
    {
      'is-disabled': disabled,
      'is-errored': isValid === false,
      'is-valid': isValid
    }
  ]">
    <label class="z-input-file__label">
      <input
          class="z-input-file__input"
          type="file"
          :name="name"
          ref="file"
          :multiple="multiple"
          :disabled="disabled"
          @change="onChange($event.target)"
          :accept="accept"
      />
      <span class="z-input-file__icon"></span>
      <span v-html="labelText"></span>
    </label>
    <p class="z-input-file__required-info" v-if="requiredInfo" v-html="requiredInfo"></p>
    <p class="z-input-file__description" v-if="description" v-html="description"></p>
    <ul
        v-if="files.length"
        class="z-input-file__list"
    >
      <li
          class="z-input-file__item"
          v-for="(file, index) in files"
          :key="index"
      >
        <p class="z-input-file__item-name">
          <span>{{ file.name }}</span>
          <span
              class="z-input-file__item-clear"
              @click="remove(file)"
          ></span>
        </p>
        <p class="z-input-file__item-info">
          <template v-if="file.type">{{ file.type }}</template>
          <template v-if="file.size">({{ getFormatedSize(file.size) }})</template>
        </p>
      </li>
    </ul>
    <span
        :class="[
        'z-input-file__error',
        errorClass
      ]"
        v-html="error"
        v-if="error && !isValid"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'z-input-file',
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
    },
    requiredInfo: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: String,
    placeholder: String,
    errorClass: {
      type: String,
      default: ''
    },
    maxSize: {
      type: Number,
      default: 3145728, // 3Mb
    },
  },
  data() {
    return {
      files: [],
      uploaded: [],
      isValid: null,
      error: '',
      text: {
        label: {
          ru: 'Прикрепить файлы',
          en: 'Attach files'
        },
        errors: {
          required: {
            ru: 'Поле обязательно для заполнения',
            en: 'Required field',
            cn: '填项目'
          },
          maxFileSize: {
            ru: `Файл не должен превышать: ${this.getFormatedSize(this.maxSize)}`,
            en: `Max filesize is ${this.getFormatedSize(this.maxSize)}`
          },
        }
      }
    }
  },
  computed: {
    labelText() {
      if (this.placeholder) return this.placeholder
      return localize(this.text.label)
    }
  },
  methods: {
    onChange(target) {
      this.updateList(target.files)
      this.uploaded = target.files
      this.$nextTick(() => this.$emit('change', this.uploaded))
      this.validate()
    },
    clear() {
      this.files = []
      this.uploaded = []
      this.$refs.file.value = null
      this.$nextTick(() => this.$emit('change', this.uploaded))
      this.validate()
    },
    updateList(data) {
      this.files = Array.from(data).map(item => {
        const re = /(?:\.([^.]+))?$/

        return {
          name: item.name,
          size: item.size,
          type: re.exec(item.name)[1]
        }
      })
    },
    remove(file) {
      let dt = new DataTransfer()
      Array.from(this.$refs.file.files).forEach(item => {
        if (file.name !== item.name) {
          dt.items.add(item)
        }
      })

      if (dt.files.length === 0) {
        this.clear()
        return
      }

      this.$refs.file.files = dt.files
      this.uploaded = dt.files
      this.updateList(this.$refs.file.files)
      this.$nextTick(() => this.$emit('change', this.uploaded))
      this.validate()
    },
    getFormatedSize(size) {
      let result = size / 1024
      if (result >= 1024) {
        return `${(result / 1024).toFixed(1)} Mb`
      }

      return `${result.toFixed(1)} Kb`
    },
    validate() {
      this.isValid = true
      if (this.required && this.uploaded.length === 0) {
        this.isValid = false
        this.error = localize(this.text.errors.required)
        return
      }

      if (this.uploaded.length > 0) {
        this.files.forEach(file => {
          if(file.size > this.maxSize){
            this.error = localize(this.text.errors.maxFileSize)
            this.files = []
            this.uploaded = []
            this.$refs.file.value = null
            this.$nextTick(() => this.$emit('change', this.uploaded))
            this.isValid = false

            return
          }
        })
        if(!this.isValid){
           return;
        }
      }

      this.error = ''
    }
  }
}

</script>

<style lang="scss">
$errorColor: #CA3D21;

.z-input-file {
  $parent: &;

  &__description {
    margin-top: 8px;
    font-size: 14px;
    margin-bottom: 24px;
    color: $token-colors-black-60;
  }

  &__required-info{
    margin: 8px 0 0;
    font-size: 14px;
    color: $token-colors-black-60;
  }

  &__icon {
    width: 12px;
    height: 23px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='23' viewBox='0 0 12 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.0428 7.05681C11.0428 6.72544 10.7742 6.45681 10.4428 6.45681C10.1115 6.45681 9.84285 6.72544 9.84285 7.05681L9.84285 17.5568C9.84285 18.5912 9.43195 19.5831 8.70056 20.3145C7.96917 21.0459 6.97719 21.4568 5.94285 21.4568C4.9085 21.4568 3.91652 21.0459 3.18513 20.3145C2.45374 19.5831 2.04284 18.5912 2.04284 17.5568L2.04285 3.86986C2.04285 2.59911 3.10319 1.54363 4.44285 1.54363C5.7825 1.54363 6.84285 2.59911 6.84285 3.86986L6.84285 17.3699C6.84285 17.6085 6.74803 17.8375 6.57925 18.0062C6.41047 18.175 6.18155 18.2698 5.94286 18.2698C5.70417 18.2698 5.47525 18.175 5.30648 18.0062C5.1377 17.8375 5.04288 17.6085 5.04288 17.3699L5.04288 6.86986C5.04288 6.53849 4.77425 6.26986 4.44288 6.26986C4.11151 6.26986 3.84288 6.53849 3.84288 6.86986L3.84288 17.3699C3.84288 17.9268 4.06412 18.4609 4.45795 18.8548C4.85177 19.2486 5.38591 19.4698 5.94286 19.4698C6.49981 19.4698 7.03395 19.2486 7.42777 18.8548C7.8216 18.4609 8.04285 17.9268 8.04285 17.3699L8.04285 3.86986C8.04285 1.90838 6.4169 0.343628 4.44285 0.343628C2.46879 0.343628 0.842846 1.90838 0.842846 3.86986L0.842845 17.5568C0.842845 18.9094 1.38016 20.2066 2.3366 21.1631C3.29304 22.1195 4.59024 22.6568 5.94285 22.6568C7.29545 22.6568 8.59265 22.1195 9.54909 21.1631C10.5055 20.2066 11.0428 18.9094 11.0428 17.5568L11.0428 7.05681Z' fill='%230077C8'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    margin-right: 8px;
  }

  &__input {
    display: none;
  }

  &__label {
    display: flex;
    align-items: center;
    color: #0077C8;
    font-size: 18px;
    transition: color 0.2s ease-in;
    cursor: pointer;

    &:hover {
      color: #4B5368;
    }

    &:active {
      color: $token-colors-black-60;
    }
  }

  &__list {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px 32px;
  }

  &__item {
    position: relative !important;
    padding-left: 40px !important;
    width: calc(50% - 16px);

    @include breakpoint('v-tablet') {
      width: 100%;
    }

    & + & {
      margin-top: 0 !important;
    }

    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.0001 3.06641C9.86847 3.06641 8.78323 3.51593 7.98308 4.31608C7.18292 5.11624 6.7334 6.20148 6.7334 7.33307V36.6664C6.7334 37.798 7.18292 38.8832 7.98308 39.6834C8.78323 40.4835 9.86847 40.9331 11.0001 40.9331H33.0001C34.1317 40.9331 35.2169 40.4835 36.0171 39.6834C36.8172 38.8832 37.2667 37.798 37.2667 36.6664V14.6743C37.2668 14.6719 37.2668 14.6695 37.2668 14.6671C37.2668 14.6469 37.2658 14.6268 37.2638 14.6071C37.2597 14.5662 37.2515 14.526 37.2393 14.4871C37.2106 14.3955 37.1601 14.3113 37.091 14.2421L26.091 3.24214C25.9785 3.12962 25.8259 3.06641 25.6667 3.06641H11.0001ZM35.2189 14.0671L26.2668 5.11498V14.0671H35.2189ZM25.0668 4.26641H11.0001C10.1867 4.26641 9.40672 4.5895 8.8316 5.16461C8.25649 5.73972 7.9334 6.51974 7.9334 7.33307V36.6664C7.9334 37.4797 8.25649 38.2598 8.8316 38.8349C9.40672 39.41 10.1867 39.7331 11.0001 39.7331H33.0001C33.8134 39.7331 34.5934 39.41 35.1685 38.8349C35.7436 38.2598 36.0667 37.4797 36.0667 36.6664V15.2671H25.6668C25.3354 15.2671 25.0668 14.9985 25.0668 14.6671V4.26641ZM14.0668 23.8338C14.0668 23.5024 14.3354 23.2338 14.6668 23.2338H29.3334C29.6648 23.2338 29.9334 23.5024 29.9334 23.8338C29.9334 24.1652 29.6648 24.4338 29.3334 24.4338H14.6668C14.3354 24.4338 14.0668 24.1652 14.0668 23.8338ZM14.6668 30.5671C14.3354 30.5671 14.0668 30.8358 14.0668 31.1671C14.0668 31.4985 14.3354 31.7671 14.6668 31.7671H29.3334C29.6648 31.7671 29.9334 31.4985 29.9334 31.1671C29.9334 30.8358 29.6648 30.5671 29.3334 30.5671H14.6668ZM14.0668 16.5005C14.0668 16.1691 14.3354 15.9005 14.6668 15.9005H16.5001H18.3334C18.6648 15.9005 18.9334 16.1691 18.9334 16.5005C18.9334 16.8318 18.6648 17.1005 18.3334 17.1005H16.5001H14.6668C14.3354 17.1005 14.0668 16.8318 14.0668 16.5005Z' fill='%230077C8'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
      width: 32px !important;
      height: 32px !important;
      display: block !important;
      position: absolute !important;
      top: 0.2em !important;
      left: 0 !important;
      background-color: transparent !important;
    }
  }

  &__item-clear {
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.4243 6.42429C18.6586 6.18997 18.6586 5.81007 18.4243 5.57576C18.19 5.34145 17.8101 5.34145 17.5758 5.57576L12 11.1515L6.42429 5.57576C6.18997 5.34145 5.81007 5.34145 5.57576 5.57576C5.34145 5.81007 5.34145 6.18997 5.57576 6.42429L11.1515 12L5.57576 17.5758C5.34145 17.8101 5.34145 18.19 5.57576 18.4243C5.81007 18.6586 6.18997 18.6586 6.42429 18.4243L12 12.8486L17.5758 18.4243C17.8101 18.6586 18.19 18.6586 18.4243 18.4243C18.6586 18.19 18.6586 17.8101 18.4243 17.5758L12.8486 12L18.4243 6.42429Z' fill='%230077C8'/%3E%3C/svg%3E%0A");
    width: 24px;
    height: 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: 4px;
    flex-shrink: 0;
  }

  &__item-name {
    display: flex;
    font-size: 16px;
    color: $token-colors-black-60;
    line-height: 140%;
    margin-bottom: 0;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__item-info {
    font-size: 13px;
    color: $token-colors-black-30;
    line-height: 1;
    margin-top: 2px;
    text-transform: uppercase;
  }

  &__error {
    font-size: 0.8125em;
    color: $errorColor;
    position: relative;
    left: 20px;
  }

  // disabled state
  &.is-disabled {
    #{$parent}__label {
      color: #BFBFBF;
    }
  }

  // errored state
  &.is-errored {
    #{$parent}__label {
      color: $errorColor;
    }
  }
}
</style>
