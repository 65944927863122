<template>
  <button kind="text" :disabled="disabled" @click="clickHandler">
    <svg version="1.1" viewBox="0 0 24 24" class="z-icon z-fill" style="width: 20px; height: 20px;"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.6 3a.6.6 0 10-1.2 0v10.552L7.424 9.576a.6.6 0 10-.848.849l5 5a.6.6 0 00.848 0l5-5a.6.6 0 00-.848-.849L12.6 13.552V3zm-9 12a.6.6 0 10-1.2 0v4A2.6 2.6 0 005 21.6h14a2.6 2.6 0 002.6-2.6v-4a.6.6 0 10-1.2 0v4a1.4 1.4 0 01-1.4 1.4H5A1.4 1.4 0 013.6 19v-4z" fill="#0077C8"></path></svg>
      Скачать выбранные позиции
  </button>
</template>

<script>
import { getSalePageFile } from '../../api/illiquid-file'
import {debounce} from 'throttle-debounce'
import qs from "query-string";

export default {
  name: 'download-excel-btn',
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    disabled: Boolean
  },
  methods: {
    clickHandler: debounce(300, function (params) {
      let queryParams = qs.stringify(this.params, {arrayFormat: 'bracket'})
      getSalePageFile(queryParams)
          .then((response) => {
            if (response.status !== 'success') {
              showNotyfications(response.message, {type: 'error'})
              return
            }

            const downloadLink = document.createElement('a')
            downloadLink.href = window.location.origin + response.link
            downloadLink.click()
          })
    })
  }
}
</script>
