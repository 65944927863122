var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-profile" }, [
    _vm.data && _vm.data.documents && _vm.data.documents.length
      ? _c("section", { staticClass: "user-profile__info" }, [
          _c("div", [
            _c("h2", {
              domProps: { innerHTML: _vm._s(_vm.TEXT.documents.title) }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "user-profile__documents" },
              _vm._l(_vm.data.documents, function(item, index) {
                return _c("div", { key: index, staticClass: "document" }, [
                  item.type && item.type.value
                    ? _c("h3", {
                        staticClass: "u-bottom-margin--2xs-important",
                        domProps: { innerHTML: _vm._s(item.type.value) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h4", {
                    staticClass: "document_name u-bottom-margin--2xs-important",
                    domProps: { innerHTML: _vm._s(item.name) }
                  }),
                  _vm._v(" "),
                  item.text && item.text.value
                    ? _c("p", {
                        staticClass: "document__description",
                        domProps: { innerHTML: _vm._s(item.text.value) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.file && item.file.link
                    ? _c("div", { staticClass: "file-list" }, [
                        _c("ul", { staticClass: "file-list__group" }, [
                          _c(
                            "li",
                            {
                              class:
                                "file-list__item file-list__item--default file-list__item--" +
                                item.file.type
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "link file-list__link",
                                  attrs: {
                                    href: item.file.link,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.TEXT.download))]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "file-list__size u-uppercase" },
                                [
                                  _vm._v(
                                    _vm._s(item.file.type) +
                                      ", " +
                                      _vm._s(item.file.size)
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _vm._m(0)
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.data && _vm.data.contacts && _vm.data.contacts.length
      ? _c(
          "section",
          [_c("Contacts", { attrs: { data: _vm.data.contacts } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "section",
      [
        _c("h2", { domProps: { innerHTML: _vm._s(_vm.TEXT.commecialOffer) } }),
        _vm._v(" "),
        _vm.auth && _vm.auth.authorized
          ? _c("CommercialOfferForm")
          : _c("p", [
              _vm._v("Для отправки коммерческого предложения, необходимо "),
              _c("a", { staticClass: "link", attrs: { href: "/auth" } }, [
                _vm._v("авторизоваться")
              ]),
              _vm._v(".")
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-profile__qr" }, [
      _c("p", { staticClass: "u-bottom-margin--2xs" }, [
        _vm._v("QR код для оплаты")
      ]),
      _vm._v(" "),
      _c("img", {
        attrs: { src: "/local/templates/nornik/img/qr-kolagmk.png", alt: "" }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1379ebd5", { render: render, staticRenderFns: staticRenderFns })
  }
}