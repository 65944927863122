var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-field" },
    [
      _c("h3", {
        staticClass: "u-bottom-margin--2xs-important",
        domProps: { innerHTML: _vm._s(_vm.title) }
      }),
      _vm._v(" "),
      _vm.list && _vm.list.length
        ? _c("div", { staticClass: "u-bottom-margin--s" }, [
            _c("h5", {
              staticClass: "u-bottom-margin--2xs",
              domProps: {
                innerHTML: _vm._s(_vm.TEXT.description.upLoadedFiles)
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "file-field__container" }, [
              _c(
                "ul",
                { staticClass: "z-input-file__list" },
                _vm._l(_vm.list, function(item, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "z-input-file__item" },
                    [
                      _c("p", { staticClass: "z-input-file__item-name" }, [
                        _c("span", [_vm._v(_vm._s(item.name))]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "z-input-file__item-clear",
                          on: {
                            click: function($event) {
                              return _vm.deleteFile(item)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "z-input-file__item-info" }, [
                        _vm._v(
                          _vm._s(item.type) + " (" + _vm._s(item.size) + ")"
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("z-input-file", {
        ref: "charter",
        attrs: {
          description: _vm.TEXT.description.acceptedFile,
          "required-info": _vm.requiredInfo,
          name: "charter",
          accept: ".pdf, .png, .jpg, .jpeg, .xlsx, .docx",
          multiple: "",
          "max-size": 2097152
        },
        on: { change: _vm.fileOnChange }
      }),
      _vm._v(" "),
      _vm.error
        ? _c("span", {
            staticClass: "file-field__error",
            domProps: { innerHTML: _vm._s(_vm.error) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-448b3ccd", { render: render, staticRenderFns: staticRenderFns })
  }
}