var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "used-products-card" }, [
    _c("h4", {
      staticClass: "used-products-card__title",
      domProps: { innerHTML: _vm._s(_vm.title) }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "used-products-card__info" }, [
      _vm.roks
        ? _c(
            "div",
            {
              staticClass:
                "used-products-card__info-item used-products-card__info-item--roks"
            },
            [
              _c("p", { staticClass: "used-products-card__subtitle" }, [
                _vm._v("РОКС")
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "used-products-card__info-value",
                domProps: { innerHTML: _vm._s(_vm.roks) }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.area
        ? _c(
            "div",
            {
              staticClass:
                "used-products-card__info-item used-products-card__info-item--area"
            },
            [
              _c("p", { staticClass: "used-products-card__subtitle" }, [
                _vm._v("Площадка")
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "used-products-card__info-value",
                domProps: { innerHTML: _vm._s(_vm.area) }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.unit
        ? _c(
            "div",
            {
              staticClass:
                "used-products-card__info-item used-products-card__info-item--unit"
            },
            [
              _c("p", { staticClass: "used-products-card__subtitle" }, [
                _vm._v("Ед. измерения")
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "used-products-card__info-value",
                domProps: { innerHTML: _vm._s(_vm.unit) }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.price
        ? _c(
            "div",
            {
              staticClass:
                "used-products-card__info-item used-products-card__info-item--price"
            },
            [
              _c("p", { staticClass: "used-products-card__subtitle" }, [
                _vm._v("Цена без НДС")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "used-products-card__info-value" }, [
                _c("span", {
                  staticClass: "used-products-card__info-inner",
                  domProps: { innerHTML: _vm._s(_vm.price) }
                }),
                _vm._v(" руб.\n            ")
              ])
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "used-products-card__body" }, [
      _c("p", { staticClass: "used-products-card__subtitle" }, [
        _vm._v("Тех. характеристика")
      ]),
      _vm._v(" "),
      _c("p", {
        staticClass: "used-products-card__description",
        domProps: { innerHTML: _vm._s(_vm.description) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8d12bcc8", { render: render, staticRenderFns: staticRenderFns })
  }
}