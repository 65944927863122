var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items && _vm.items.length
    ? _c(
        "div",
        { staticClass: "illiquid-results" },
        [
          _c(
            "div",
            { staticClass: "illiquid-results__actions" },
            [
              _c(
                "button",
                {
                  staticClass: "illiquid-results__actions-button",
                  on: { click: _vm.toggleSelectAllCheckboxes }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "z-icon z-fill",
                      staticStyle: { width: "20px", height: "20px" },
                      attrs: { version: "1.1", viewBox: "0 0 24 24" }
                    },
                    [
                      _c(
                        "g",
                        { attrs: { "clip-path": "url(#clip0_9226_31769)" } },
                        [
                          _c("path", {
                            attrs: {
                              pid: "0",
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M9.928 2.826a9.4 9.4 0 015.898.582.6.6 0 00.488-1.096 10.6 10.6 0 106.286 9.689v-.92a.6.6 0 10-1.2 0V12A9.4 9.4 0 119.928 2.826zm12.496 1.598a.6.6 0 10-.849-.848L12 13.162l-2.576-2.576a.6.6 0 00-.848.849l3 3a.6.6 0 00.848 0l10-10.01z",
                              fill: "#0077C8"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("defs", [
                        _c("clipPath", { attrs: { id: "clip0_9226_31769" } }, [
                          _c("path", {
                            attrs: {
                              pid: "1",
                              fill: "#fff",
                              d: "M0 0h24v24H0z"
                            }
                          })
                        ])
                      ])
                    ]
                  ),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.isAllSelected
                          ? "Очистить все позиции"
                          : "Выбрать все позиции"
                      ) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "illiquid-results__actions-button",
                  attrs: { disabled: !_vm.checkedItems.length },
                  on: { click: _vm.toggleShowSelected }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "z-icon z-fill",
                      staticStyle: { width: "20px", height: "20px" },
                      attrs: { version: "1.1", viewBox: "0 0 24 24" }
                    },
                    [
                      _c(
                        "g",
                        { attrs: { "clip-path": "url(#clip0_9226_31858)" } },
                        [
                          _c("path", {
                            attrs: {
                              pid: "0",
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M2.238 12.925a16.92 16.92 0 01-.53-.875L1.681 12l.027-.05a19.075 19.075 0 012.604-3.54C6.138 6.466 8.74 4.6 12 4.6s5.863 1.865 7.687 3.81a19.08 19.08 0 012.604 3.54l.028.05-.028.05a19.081 19.081 0 01-2.604 3.54C17.863 17.536 15.26 19.4 12 19.4c-3.26 0-5.863-1.864-7.687-3.81a19.077 19.077 0 01-2.075-2.665zm21.298-1.193L23 12c.537.269.537.269.536.27v.002l-.004.005-.01.02-.02.038-.018.036a18.13 18.13 0 01-.714 1.205 20.28 20.28 0 01-2.207 2.835C18.637 18.464 15.74 20.6 12 20.6s-6.637-2.136-8.563-4.19a20.28 20.28 0 01-2.774-3.773 12.172 12.172 0 01-.185-.34l-.01-.02-.003-.006-.001-.002L1 12a66.968 66.968 0 01-.537-.268v-.001l.002-.002.003-.006.01-.02a7.484 7.484 0 01.185-.34A20.274 20.274 0 013.438 7.59C5.362 5.535 8.26 3.4 12 3.4c3.74 0 6.637 2.135 8.563 4.189a20.274 20.274 0 012.774 3.773 11.962 11.962 0 01.185.34l.01.02.003.006.001.002zM23 12l.537-.268a.6.6 0 010 .537L23 12zM.463 11.732L1 12l-.537.269a.6.6 0 010-.537zM9.6 12a2.4 2.4 0 114.8 0 2.4 2.4 0 01-4.8 0zM12 8.4a3.6 3.6 0 100 7.2 3.6 3.6 0 000-7.2z",
                              fill: "#0077C8"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("defs", [
                        _c("clipPath", { attrs: { id: "clip0_9226_31858" } }, [
                          _c("path", {
                            attrs: {
                              pid: "1",
                              fill: "#fff",
                              d: "M0 0h24v24H0z"
                            }
                          })
                        ])
                      ])
                    ]
                  ),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        !_vm.showSelectedOnly
                          ? "Посмотреть выбранные"
                          : "Посмотреть все"
                      ) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("download-exel-btn", {
                staticClass: "illiquid-results__actions-button",
                attrs: {
                  params: { id: [].concat(_vm.checkedItems) },
                  disabled: !_vm.checkedItems.length
                }
              }),
              _vm._v(" "),
              _c("download-all-btn", {
                staticClass: "illiquid-results__actions-button",
                attrs: {
                  params: Object.assign({}, _vm.params, {
                    page: 1,
                    count: undefined
                  })
                }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "illiquid-results__link",
                  attrs: { href: "/personal-area/" }
                },
                [
                  _vm._v("\n      Подать коммерческое предложение\n      "),
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "6",
                        height: "10",
                        viewBox: "0 0 6 10",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M1 9L5 5L1 1",
                          stroke: "currentColor",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.items, function(item, index) {
            return _c("div", { key: index, staticClass: "sale-page-card" }, [
              _c(
                "div",
                { staticClass: "sale-page-card__wrapper" },
                [
                  _c("label", { staticClass: "sale-page-card__checkbox" }, [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: item.id,
                        checked: _vm.checkedItems.includes(item.id)
                      },
                      on: { change: _vm.setCheckboxArray }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "sale-page-card__checkbox-box" },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "z-icon z-fill z-top",
                            staticStyle: { width: "22px", height: "22px" },
                            attrs: { version: "1.1", viewBox: "0 0 24 24" }
                          },
                          [
                            _c("path", {
                              attrs: {
                                pid: "0",
                                "fill-rule": "evenodd",
                                "clip-rule": "evenodd",
                                d:
                                  "M20.424 6.425a.6.6 0 10-.848-.849L9 16.152l-4.576-4.576a.6.6 0 00-.848.849l5 5a.6.6 0 00.848 0l11-11z",
                                fill: "#0077C8"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "sale-page-card__body" },
                    [
                      _c("span", {
                        staticClass: "sale-page-card__text",
                        domProps: { innerHTML: _vm._s(item.name) }
                      }),
                      _vm._v(" "),
                      _c(
                        "sale-page-table",
                        [
                          _c(
                            "sale-page-table-item",
                            {
                              staticClass: "sale-page-card__code",
                              attrs: { title: "Код ЕНС" }
                            },
                            [
                              item.code && item.code.value
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.code.value)
                                    }
                                  })
                                : _c("span", [_vm._v("—")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sale-page-table-item",
                            {
                              staticClass: "sale-page-card__gost",
                              attrs: { title: "ГОСТ" }
                            },
                            [
                              item.gost && item.gost.value
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.gost.value)
                                    }
                                  })
                                : _c("span", [_vm._v("—")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sale-page-table-item",
                            {
                              staticClass: "sale-page-card__brand",
                              attrs: { title: "Марка" }
                            },
                            [
                              item.brand && item.brand.value
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.brand.value)
                                    }
                                  })
                                : _c("span", [_vm._v("—")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sale-page-table-item",
                            {
                              staticClass: "sale-page-card__type",
                              attrs: { title: "Наименование группы материалов" }
                            },
                            [
                              item.type && item.type.value
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.type.value)
                                    }
                                  })
                                : _c("span", [_vm._v("—")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sale-page-table-item",
                            {
                              staticClass: "sale-page-card__subtype",
                              attrs: {
                                title: "Наименование подгруппы материалов"
                              }
                            },
                            [
                              item.subType && item.subType.value
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.subType.value)
                                    }
                                  })
                                : _c("span", [_vm._v("—")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sale-page-table-item",
                            {
                              staticClass: "sale-page-card__tech-props",
                              attrs: { title: "Технические характеристики" }
                            },
                            [
                              item.techProps && item.techProps.value
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.techProps.value)
                                    }
                                  })
                                : _c("span", [_vm._v("—")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sale-page-table-item",
                            {
                              staticClass: "sale-page-card__tech-state",
                              attrs: { title: "Тех. состояние" }
                            },
                            [
                              item.techState && item.techState.value
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.techState.value)
                                    }
                                  })
                                : _c("span", [_vm._v("—")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sale-page-table-item",
                            {
                              staticClass: "sale-page-card__amount",
                              attrs: { title: "Кол-во к&nbsp;реал-ии" }
                            },
                            [
                              item.amount && item.amount.value
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.amount.value)
                                    }
                                  })
                                : _c("span", [_vm._v("—")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sale-page-table-item",
                            {
                              staticClass: "sale-page-card__amount-name",
                              attrs: { title: "ЕИ кол." }
                            },
                            [
                              item.amountName && item.amountName.value
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.amountName.value)
                                    }
                                  })
                                : _c("span", [_vm._v("—")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sale-page-table-item",
                            {
                              staticClass: "sale-page-card__price",
                              attrs: { title: "Цена за&nbsp;единицу" }
                            },
                            [
                              item.price && item.price.value
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.formatNumber(item.price.value)
                                      )
                                    }
                                  })
                                : _c("span", [_vm._v("—")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sale-page-table-item",
                            {
                              staticClass: "sale-page-card__all-price",
                              attrs: { title: "Сумма" }
                            },
                            [
                              item.priceAll && item.priceAll.value
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.formatNumber(item.priceAll.value)
                                      )
                                    }
                                  })
                                : _c("span", [_vm._v("—")])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  item.images.length
                    ? _c("sale-card-slider", {
                        attrs: { images: item.images, id: item.id }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3a1f28d6", { render: render, staticRenderFns: staticRenderFns })
  }
}