<template>
    <div class="sale-page-table">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'sale-page-table'
}
</script>

<style lang="scss">
.sale-page-table {
    display: flex;
    flex-wrap: wrap;
    margin-top: -$token-spacers-xs;
    margin-left: -12px;
    margin-right: -12px;
    font-family: "Roboto Mono", sans-serif;

    @include breakpoint(mobile) {
        flex-direction: column;
    }
}
</style>
