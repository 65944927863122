var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data && _vm.data.length
    ? _c("div", { staticClass: "contacts" }, [
        _c("h2", { domProps: { innerHTML: _vm._s(_vm.TEXT.title) } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "contacts__container" },
          _vm._l(_vm.data, function(item, index) {
            return _c("div", { key: index, staticClass: "contact" }, [
              _c("div", { staticClass: "contact__header" }, [
                _c("p", {
                  staticClass: "contact__name",
                  domProps: { innerHTML: _vm._s(item.fio) }
                }),
                _vm._v(" "),
                item.group && item.group.value
                  ? _c("p", {
                      staticClass: "contact__description",
                      domProps: { innerHTML: _vm._s(item.group.value) }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "contact__container" }, [
                item.phone && item.phone.value
                  ? _c("div", { staticClass: "contact__row" }, [
                      _c("span", {
                        staticClass: "contact__prop",
                        domProps: { innerHTML: _vm._s(_vm.TEXT.phone) }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "contact__value",
                        domProps: { innerHTML: _vm._s(item.phone.value) }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.email && item.email.value
                  ? _c("div", { staticClass: "contact__row" }, [
                      _c("span", {
                        staticClass: "contact__prop",
                        domProps: { innerHTML: _vm._s(_vm.TEXT.email) }
                      }),
                      _vm._v(" "),
                      _c("a", {
                        staticClass: "contact__value link",
                        attrs: { href: "mailto:" + item.email.value },
                        domProps: { innerHTML: _vm._s(item.email.value) }
                      })
                    ])
                  : _vm._e()
              ])
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d3caf912", { render: render, staticRenderFns: staticRenderFns })
  }
}