<template>
  <div
      v-if="isOpen"
      class="unsubscribe-popup"
  >

    <div class="unsubscribe-popup__overlay" @click="closePopup" />

    <div class="unsubscribe-popup__inner">
      <button class="unsubscribe-popup__close" @click="closePopup">
        <svg version="1.1" viewBox="0 0 24 24" class="z-icon z-fill" style="width: 24px; height: 24px;">
          <path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.424 6.425a.6.6 0 10-.848-.849L12 11.152 6.424 5.576a.6.6 0 10-.848.849L11.15 12l-5.575 5.576a.6.6 0 00.848.849L12 12.849l5.576 5.576a.6.6 0 00.848-.849L12.848 12l5.576-5.575z" fill="#000000"></path>
        </svg>
      </button>
      <h3 class="unsubscribe-popup__title">Сожалеем, что вы&nbsp;отписались от&nbsp;нашей рассылки</h3>
      <p class="unsubscribe-popup__text">Нам очень жаль расставаться с&nbsp;вами.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      params: {
        state: '',
        id: '',
        code: '',
      }
    }
  },
  methods: {
    getQueryParams() {
      const query = window.location.search
      const queryParams = new URLSearchParams(query)
      this.params.state = queryParams.get('state')
      this.params.id = queryParams.get('id')
      this.params.code = queryParams.get('confirm-code')
    },
    closePopup() {
      this.isOpen = false;
    },
  },
  mounted() {
    this.getQueryParams()

    if (this.params.state === 'unsubscribe' && this.params.id !== '' && this.params.code !== '') {
      this.isOpen = true;
    }
  },
}
</script>

<style lang="scss">
.unsubscribe-popup {
  position: fixed;
  top: 0;
  left: 0;
  @include padding-level(top, L);
  @include padding-level(right, L);
  @include padding-level(bottom, L);
  @include padding-level(left, L);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  outline: none;
  z-index: 9999;

  &__overlay {
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(3px);
    outline: none;
  }

  &__inner {
    position: relative;
    @include padding-level(top, L);
    @include padding-level(right, L);
    @include padding-level(bottom, L);
    @include padding-level(left, L);
    background: $token-colors-white;
    border-radius: 8px;
    z-index: 1;
  }

  &__close {
    position: absolute;
    top: $token-spacers-s;
    inset-inline-end: $token-spacers-s;
    display: block;
    padding: 0;
    outline: 0;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 2;

    .z-icon{
      width: 24px;
      height: 24px;
    }
  }

  &__title {
    padding-right: 32px;
    @include typo-level(3XL, true);
  }

  &__text {
    @include typo-level(L);
  }
}
</style>