export async function sendAuthForm (data) {
    const result = await fetch(`/api/auth/login/`, {
        method: 'POST',
        body: data // FormData
    })
    
    return await result.json()
}

export async function sendRestoreForm (data) {
    const result = await fetch(`/api/auth/forgot/`, {
        method: 'POST',
        body: data // FormData
    })
    
    return await result.json()
}

export async function sendRegForm (data) {
    const result = await fetch(`/api/auth/registration/`, {
        method: 'POST',
        body: data // FormData
    })
    
    return await result.json()
}

export async function sendChangePasswordForm (data) {
    const result = await fetch(`/api/auth/change_password/`, {
        method: 'POST',
        body: data // FormData
    })
    
    return await result.json()
}

export async function logout () {
    const result = await fetch(`/auth/?action=logout`, {
        method: 'GET',
    })
}