var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c("div", { staticClass: "promotion-popup" }, [
        _c("div", {
          staticClass: "promotion-popup__overlay",
          on: { click: _vm.closePopup }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "promotion-popup__body" }, [
          _c(
            "button",
            {
              staticClass: "promotion-popup__close",
              on: { click: _vm.closePopup }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M21.0628 3.03217C21.3557 3.32506 21.3557 3.79994 21.0628 4.09283L13.1082 12.0475L21.0628 20.0022C21.3557 20.2951 21.3557 20.7699 21.0628 21.0628C20.7699 21.3557 20.2951 21.3557 20.0022 21.0628L12.0475 13.1082L4.09283 21.0628C3.79994 21.3557 3.32506 21.3557 3.03217 21.0628C2.73928 20.7699 2.73928 20.2951 3.03217 20.0022L10.9868 12.0475L3.03217 4.09283C2.73928 3.79994 2.73928 3.32506 3.03217 3.03217C3.32506 2.73928 3.79994 2.73928 4.09283 3.03217L12.0475 10.9868L20.0022 3.03217C20.2951 2.73928 20.7699 2.73928 21.0628 3.03217Z",
                      fill: "#81899D"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "promotion-popup__wrapper" }, [
            _c("div", { staticClass: "promotion-popup__icon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "36",
                    height: "36",
                    viewBox: "0 0 36 36",
                    fill: "none"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M8.83628 17.3117C3.79628 17.3117 0.436279 13.6919 0.436279 8.95053C0.436279 4.15813 3.79628 0.436371 8.83628 0.436371C13.9272 0.436371 17.3381 4.15813 17.3381 8.95053C17.3381 13.6919 13.9272 17.3117 8.83628 17.3117ZM8.9381 34.9518H5.5781L27.2654 0.9462H30.6763L8.9381 34.9518ZM27.1126 35.5636C22.0726 35.5636 18.7126 31.9439 18.7126 27.2024C18.7126 22.41 22.0726 18.6883 27.1126 18.6883C32.1526 18.6883 35.5636 22.41 35.5636 27.2024C35.5636 31.9439 32.1526 35.5636 27.1126 35.5636ZM8.83628 13.9469C11.4836 13.9469 13.3672 11.8566 13.3672 8.95053C13.3672 5.89155 11.4836 3.85223 8.83628 3.85223C6.23992 3.85223 4.35628 5.89155 4.35628 8.95053C4.35628 11.8566 6.23992 13.9469 8.83628 13.9469ZM27.1126 32.1988C29.7599 32.1988 31.6436 30.1594 31.6436 27.2024C31.6436 24.1435 29.7599 22.1041 27.1126 22.1041C24.4654 22.1041 22.6326 24.1435 22.6326 27.2024C22.6326 30.1594 24.4654 32.1988 27.1126 32.1988Z",
                      fill: "#0077C8"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "promotion-popup__content" },
              [_vm._t("default")],
              2
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-559b2c0c", { render: render, staticRenderFns: staticRenderFns })
  }
}