var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form" }, [
    !_vm.isSended
      ? _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "form__fields" },
              [
                _c(
                  "div",
                  { staticClass: "form__row" },
                  [
                    _c("ZInput", {
                      ref: "inn",
                      attrs: {
                        type: "number",
                        placeholder: _vm.TEXT.fields.inn,
                        name: "inn",
                        required: "",
                        minLength: 10
                      },
                      model: {
                        value: _vm.formData.inn,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "inn", $$v)
                        },
                        expression: "formData.inn"
                      }
                    }),
                    _vm._v(" "),
                    _c("ZInput", {
                      ref: "company",
                      attrs: {
                        name: "company",
                        placeholder: _vm.TEXT.fields.companyName
                      },
                      model: {
                        value: _vm.formData.company,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "company", $$v)
                        },
                        expression: "formData.company"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("ZInput", {
                  ref: "name",
                  attrs: {
                    placeholder: _vm.TEXT.fields.name,
                    name: "name",
                    required: ""
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form__row" },
                  [
                    _c("ZInput", {
                      ref: "surname",
                      attrs: {
                        name: "surname",
                        placeholder: _vm.TEXT.fields.surname,
                        required: ""
                      },
                      model: {
                        value: _vm.formData.surname,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "surname", $$v)
                        },
                        expression: "formData.surname"
                      }
                    }),
                    _vm._v(" "),
                    _c("ZInput", {
                      ref: "second",
                      attrs: {
                        name: "second",
                        placeholder: _vm.TEXT.fields.middleName
                      },
                      model: {
                        value: _vm.formData.second,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "second", $$v)
                        },
                        expression: "formData.second"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form__row" },
                  [
                    _c("ZInput", {
                      ref: "phone",
                      attrs: {
                        name: "phone",
                        placeholder: _vm.TEXT.fields.phone,
                        type: "tel",
                        required: "",
                        mask: "+7 (###) ###-##-##"
                      },
                      model: {
                        value: _vm.formData.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "phone", $$v)
                        },
                        expression: "formData.phone"
                      }
                    }),
                    _vm._v(" "),
                    _c("ZInput", {
                      ref: "email",
                      attrs: {
                        name: "email",
                        placeholder: _vm.TEXT.fields.email,
                        type: "email",
                        required: ""
                      },
                      model: {
                        value: _vm.formData.email,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "email", $$v)
                        },
                        expression: "formData.email"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form__row" },
                  [
                    _c("ZInput", {
                      ref: "password",
                      attrs: {
                        name: "password",
                        placeholder: _vm.TEXT.fields.password,
                        type: "password",
                        required: "",
                        "min-length": 8
                      },
                      model: {
                        value: _vm.formData.password,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "password", $$v)
                        },
                        expression: "formData.password"
                      }
                    }),
                    _vm._v(" "),
                    _c("ZInput", {
                      ref: "password_repeat",
                      attrs: {
                        name: "password_repeat",
                        placeholder: _vm.TEXT.fields.passwordRepeat,
                        type: "password",
                        required: ""
                      },
                      on: { change: _vm.onPasswordRepeatChange },
                      model: {
                        value: _vm.formData.password_repeat,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "password_repeat", $$v)
                        },
                        expression: "formData.password_repeat"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "form__field-description",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.TEXT.description.passwordRequirements
                        )
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("z-checkbox", {
                  ref: "subscribe",
                  attrs: { name: "subscribe", data: [{ value: "true" }] },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "text",
                        fn: function() {
                          return [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.TEXT.fields.subscribe)
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3925385207
                  ),
                  model: {
                    value: _vm.formData.subscribe,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "subscribe", $$v)
                    },
                    expression: "formData.subscribe"
                  }
                }),
                _vm._v(" "),
                _c("z-checkbox", {
                  ref: "agree",
                  attrs: {
                    name: "agree",
                    data: [{ value: "true" }],
                    required: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "text",
                        fn: function() {
                          return [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.TEXT.fields.policy)
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1272783359
                  ),
                  model: {
                    value: _vm.formData.agree,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "agree", $$v)
                    },
                    expression: "formData.agree"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.errorMessage
              ? _c("p", {
                  staticClass: "form__error",
                  domProps: { innerHTML: _vm._s(_vm.errorMessage) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form__footer" },
              [
                _c("ZButton", { attrs: { loading: _vm.isLoading } }, [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.TEXT.button.reg) }
                  })
                ])
              ],
              1
            )
          ]
        )
      : _c("div", {
          staticClass: "form__success",
          domProps: { innerHTML: _vm._s(_vm.TEXT.successMessage.regSuccess) }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7ea04da8", { render: render, staticRenderFns: staticRenderFns })
  }
}