<template>
    <div class="form">
        <form @submit.prevent="onSubmit" v-if="!isSended">
            <div class="form__fields">
                <div class="form__row">
                    <ZInput
                        type="number"
                        :placeholder="TEXT.fields.inn"
                        ref="inn"
                        name="inn"
                        required
                        :minLength="10"
                        v-model="formData.inn"
                    />

                    <ZInput
                        name="company"
                        :placeholder="TEXT.fields.companyName"
                        ref="company"
                        v-model="formData.company"
                    />
                </div>

                <ZInput
                    :placeholder="TEXT.fields.name"
                    ref="name"
                    name="name"
                    required
                    v-model="formData.name"
                />

                <div class="form__row">
                    <ZInput
                        name="surname"
                        :placeholder="TEXT.fields.surname"
                        ref="surname"
                        required
                        v-model="formData.surname"
                    />
                    <ZInput
                        name="second"
                        :placeholder="TEXT.fields.middleName"
                        ref="second"
                        v-model="formData.second"
                    />
                </div>
                <div class="form__row">
                    <ZInput
                        name="phone"
                        :placeholder="TEXT.fields.phone"
                        type="tel"
                        ref="phone"
                        required
                        v-model="formData.phone"
                        mask="+7 (###) ###-##-##"
                    />
                    <ZInput
                        name="email"
                        :placeholder="TEXT.fields.email"
                        type="email"
                        ref="email"
                        required
                        v-model="formData.email"
                    />
                </div>

                <div class="form__row">
                    <ZInput
                        name="password"
                        :placeholder="TEXT.fields.password"
                        type="password"
                        ref="password"
                        required
                        :min-length="8"
                        v-model="formData.password"
                    />
                    <ZInput
                        name="password_repeat"
                        :placeholder="TEXT.fields.passwordRepeat"
                        type="password"
                        ref="password_repeat"
                        required
                        @change="onPasswordRepeatChange"
                        v-model="formData.password_repeat"
                    />
                    <span class="form__field-description" v-html="TEXT.description.passwordRequirements" />
                </div>

                <z-checkbox
                    v-model="formData.subscribe"
                    name="subscribe"
                    ref="subscribe"
                    :data="[{ value: 'true' }]"
                >
                    <template #text>
                        <span v-html="TEXT.fields.subscribe"/>
                    </template>
                </z-checkbox>
                
                <z-checkbox
                    v-model="formData.agree"
                    name="agree"
                    ref="agree"
                    :data="[{ value: 'true' }]"
                    required
                >
                    <template #text>
                        <span v-html="TEXT.fields.policy"/>
                    </template>
                </z-checkbox>
            </div>

            <p class="form__error" v-if="errorMessage" v-html="errorMessage"></p>
            <div class="form__footer">
                <ZButton :loading="isLoading">
                    <span v-html="TEXT.button.reg" />
                </ZButton>

            </div>
        </form>
        <div class="form__success" v-else v-html="TEXT.successMessage.regSuccess" />
    </div>
</template>


<script>
import { debounce } from 'throttle-debounce'
import ZButton from '../../app/ZButton'
import ZInput from '../../app/ZInput'
import ZCheckbox from '../../app/ZCheckbox'
import { sendRegForm as sendForm } from '../api/auth'
import buildFormData from '../utils/buildFormData'
import { TEXT, RESPONSE_STATUS } from './const'

const REQUIRED_FIELDS = ['inn', 'name', 'surname', 'second', 'phone', 'email', 'password', 'password_repeat', 'agree']

export default {
    name: 'reg-form',
    components: {
        ZButton,
        ZInput,
        ZCheckbox
    },
    data () {
        return {
            TEXT,
            formData: {
                inn: '',
                name: '',
                surname: '',
                second: '',
                phone: '',
                email: '',
                company: '',
                password: '',
                password_repeat: '',
                subscribe: [],
                agree: []
            },
            errorMessage: '',
            isLoading: false,
            isSended: false
        }
    },
    methods: {
        clearPhone (str) {
            return str.replace(/[ \(\)-\.]/g, '')
        },
        onSubmit: debounce(500, async function (event) {
            this.errorMessage = '';
            const isValid = this.validate()

            if (!isValid) return
            const formData = buildFormData({ ...this.formData, phone: this.clearPhone(this.formData.phone)})

            this.isLoading = true
            const response = await sendForm(formData)
            this.isLoading = false

            console.log(response);
            if (response.status === RESPONSE_STATUS.error) {
                this.errorMessage = response.message
                return
            }
            if (response.status === RESPONSE_STATUS.success) {
                this.isSended = true
            }
        }),
        validate () {
            let isValid = true

            REQUIRED_FIELDS.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })

            return isValid
        },
        onPasswordRepeatChange () {
            // to do (add custom validation to repeat password field)
            console.log(this.formData.password_repeat, this.formData.password);
        }
    }
}
</script>

<style lang="scss" scoped>
@import './form.scss';
.auth-form {

}
</style>