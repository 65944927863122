export const TEXT = {
    button: {
        send: 'Отправить',
        logout: 'Выйти',
    },
    fields: {
        charter: 'Устав',
        inn: 'ИНН',
        egrul: 'ЕГРЮЛ',
        'order-appointment-director': 'Приказ о назначении директора',
        balance: 'Бухгалтерский баланс',
        'certificate-absence-debt': 'Справка об отсутствии задолженности',
        'additional-docs': 'Дополнительные документы',
        'commercial-offer': 'Коммерческое предложение',
        message: 'Сообщение'
    },
    description: {
        acceptedFile: 'Поддерживаемые форматы файлов — pdf, png, jpg, jpeg, xlsx, docx',
        upLoadedFiles: 'Загруженные файлы',
    },
    requiredInfo: {
        individuals: 'Обязательное поле для физического лица',
        legalEntitry: 'Обязательное поле для юридического лица',
        both: 'Обязательное поле для юридического и физического лица',
    },
    successMessage: 'Ваша заявка успешно отправлена!',
    errorMessage: 'Извините, что-то пошло не так, попробуйте повторить позднее.'
}

export const RESPONSE_STATUS = {
    error: 'error',
    success: true
}

const USER_PROFILE_LINK = '/personal-area/'