export const AUTH_STATE = {
    auth: 'auth',
    reg: 'reg',
    restore: 'restore',
    changePassword: 'changePassword',
    confirmEmail: 'confirmEmail',
}

export const TEXT = {
    button: {
        login: 'Войти',
        reg: 'Регистрация',
        send: 'Отправить',
    },
    links: {
        reg: 'Регистрация',
        restore: 'Восстановить',
        login: 'Войти'
    },
    title: {
        auth: 'Авторизация',
        restore: 'Восстановление пароля',
        reg: 'Регистрация',
        changePassword: 'Сменить пароль',
        confirmEmail: 'Подтвердить почту',
    },
    description: {
        hasNotAccout: 'Ещё нет аккаунта?',
        alredyHasAccout: 'У вас уже есть аккаунт?',
        forgotPassword: 'Забыли пароль?',
        passwordRequirements: 'Пароль должен быть от 8 до 100 символов и включать цифры, латинские буквы в верхнем и нижнем регистре.',
        restoreForm: 'Введите электронную почту, на которую зарегистрирован ваш аккаунт. <br> Мы отправим вам письмо с инструкциями.',
    },
    successMessage: {
        emailConfirmed: 'Ваш адрес электронной почты успешно подтвержден.',
        passwordChanged: 'Ваш пароль успешно изменен.',
        regSuccess: 'На указанный e-mail отправлено письмо с подтверждением. <br> Пожалуйста пройдите по ссылке из письма для завершения регистрации.',
        restoreEmail: 'Ссылка для восстановления пароля отправлена на указанный email.',
    },
    fields: {
        policy: 'В соответствии с Федеральным законом «О персональных данных» от 27.07.2006 N 152-ФЗ свободно, своей волей и в своем интересе я даю свое согласие AO «Кольская ГМК» на обработку моих персональных данных, указанных при заполнении формы регистрации на сайте <a class="link" href="www.kolagmk.ru" target="_blank">www.kolagmk.ru</a>',
        subscribe: 'Подписаться на рассылку',
        email: 'E-mail',
        phone: 'Телефон',
        name: 'Имя',
        surname: 'Фамилия',
        middleName: 'Отчество',
        password: 'Пароль',
        passwordRepeat: 'Повторите пароль',
        inn: 'ИНН',
        companyName: 'Название компании'
    }
}

export const RESPONSE_STATUS = {
    error: 'error',
    success: true
}

const USER_PROFILE_LINK = '/personal-area/'