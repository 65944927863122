<template>
    <div class="form">
        <form @submit.prevent="onSubmit" v-if="!isSended">
            <div class="form__fields">
                <ZInput
                    class="form__field"
                    name="password"
                    :placeholder="TEXT.fields.password"
                    type="password"
                    ref="password"
                    required
                    :min-length="8"
                    v-model="formData.password"
                />
                <ZInput
                    class="form__field"
                    name="password_repeat"
                    :placeholder="TEXT.fields.passwordRepeat"
                    type="password"
                    ref="password_repeat"
                    required
                    v-model="formData.password_repeat"
                />
            </div>
            
            <p class="form__field-description" v-html="TEXT.description.passwordRequirements" />
            <p class="form__error" v-if="errorMessage" v-html="errorMessage"></p>
            <div class="form__footer">
                <ZButton :loading="isLoading">
                    <span v-html="TEXT.button.send" />
                </ZButton>
            </div>
        </form>
        <div class="form__success" v-else>
            <span v-html="TEXT.successMessage.passwordChanged" /> 
            <span class="link cursor-pointer" @click="changeState" v-html="TEXT.links.login" />
        </div>

    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import ZButton from '../../app/ZButton'
import ZInput from '../../app/ZInput'
import { sendChangePasswordForm as sendForm } from '../api/auth'
import buildFormData from '../utils/buildFormData'
import parseGetParams from '../utils/parseGetParams'
import { AUTH_STATE, TEXT, RESPONSE_STATUS } from './const'

const REQUIRED_FIELDS = ['email']

export default {
    name: 'restore-form',
    components: {
        ZButton,
        ZInput
    },
    data () {
        return {
            TEXT,
            formData: {
                password: '',
                password_repeat: '',
                checkword: '',
                login: ''
            },
            errorMessage: '',
            isLoading: false,
            isSended: false
        }
    },
    methods: {
        changeState (state) {
            this.$emit('change-state', AUTH_STATE.auth)
        },
        onSubmit: debounce(500, async function (event) {
            this.errorMessage = '';
            const isValid = this.validate()

            if (!isValid) return
            const formData = buildFormData(this.formData)

            this.isLoading = true
            const response = await sendForm(formData)
            this.isLoading = false

            if (response.status === RESPONSE_STATUS.error) {
                this.errorMessage = response.message
                return
            }
            if (response.status === RESPONSE_STATUS.success) {
                this.isSended = true
            }
        }),
        validate () {
            let isValid = true

            REQUIRED_FIELDS.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })

            return isValid
        },
        init () {
            const getParams = parseGetParams()
            this.formData.checkword = getParams.checkword
            this.formData.login = getParams.login
        }
    },
    created () {
        this.init()
    }
}
</script>

<style lang="scss" scoped>
@import './form.scss';
.auth-form {

}
</style>