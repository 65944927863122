import Vue from 'vue';

const container = document.getElementById('non-core-assets-wapper')
const exists = typeof (container) != 'undefined' && container != null


import Auth from './auth/Auth.vue'
import AuthLink from './auth/AuthLink.vue'
import UserProfile from './UserProfile/UserProfile.vue'
import IlliquidPage from './Illiquid/IlliquidPage.vue'
import PromotionPopup from './Illiquid/components/PromotionPopup.vue'

if (exists) {
    new Vue({
        el: '#non-core-assets-wapper',
        components: {
            Auth,
            UserProfile,
            IlliquidPage,
            PromotionPopup
        },
        data: {
            lang: getLang(),
            app: window.App
        },
    });
};

const containerAuthLink = document.getElementById('auth-link-wrapper')
const existsAuthLinkContainer = typeof (containerAuthLink) != 'undefined' && containerAuthLink != null

if (existsAuthLinkContainer) {
    new Vue({
        el: '#auth-link-wrapper',
        components: {
            AuthLink
        },
        data: {
            lang: getLang(),
            app: window.App
        },
    });
};
