<template>
  <div class="illiquid-page">
    <div class="illiquid-page__contacts u-bottom-margin--3xl">
      <Contacts :data="contacts"/>
      <div class="illiquid-page__qr">
        <p class="u-bottom-margin--2xs">QR код для оплаты</p>
        <img class="illiquid-page__qr-image" src="/local/templates/nornik/img/qr-kolagmk.png" alt="">
      </div>
    </div>
    <section class="u-bottom-margin--xs">
      <card-link
          image-size="76%"
          image="/images/map-circle-block.svg"
          :exclamation="false"
      >
        <p>Информируем об&nbsp;открытии склада&nbsp;&mdash; магазина СопчаСнаб по&nbsp;адресу&nbsp;г. Мончегорск, пос. Малая Сопча</p>
        <p><a class="link link--external" href="https://yandex.com/maps/-/CDTSyWlv" target="_blank"><span>Смотреть на&nbsp;карте</span></a></p>
      </card-link>
    </section>
    <div class="illiquid-page__notifications u-bottom-margin--m">
      <card-link
          class="u-bottom-margin--xs"
          link="https://www.nornickel.ru/suppliers/warning/"
          target="_blank"
          theme="warning"
          image="/images/spy.svg"
      >
        Предупреждение о&nbsp;подложных обращениях от&nbsp;лица компании
      </card-link>
      <card-link
          class="u-bottom-margin--xs"
          theme="warning"
          image="/images/pr.svg"
          :exclamation="false"
      >
        Для обратной связи по&nbsp;улучшению сервиса реализации неликвидных МТР просим писать на&nbsp;<span class="u-no-wrap">e-mail</span>:
        <a href="mailto:nelikvidi@kolagmk.ru">nelikvidi@kolagmk.ru</a>
      </card-link>
    </div>

    <IlliquidList/>

    <unsubscribe-popup />
  </div>
</template>

<script>
import IlliquidList from './IlliquidList.vue'
import UnsubscribePopup from './components/UnsubscribePopup.vue'
import Contacts from '../Contacts/Contacts.vue'
import CardLink from '../../app/CardLink/CardLink.vue'

export default {
  name: 'illiquid-page',
  components: {
    IlliquidList,
    Contacts,
    CardLink,
    UnsubscribePopup
  },
  data() {
    return {
      contacts: this.$root?.app?.components?.['lk-partner']?.contacts
    }
  }
}
</script>

<style lang="scss">
.illiquid-page {

  &__contacts{
    display: flex;
    justify-content: space-between;
    gap: 64px;

    @include breakpoint(v-tablet) {
      flex-direction: column;
      gap: 32px;
    }
  }

  &__qr-image{
    min-width: 200px;
    height: 200px;
    @include breakpoint(v-tablet) {
      min-width: 150px;
      height: 150px;
    }
    @include breakpoint(mobile){
      min-width: 100px;
      height: 100px;
    }
  }

  &__notifications{
    display: flex;
    gap: 24px;

    @include breakpoint(v-tablet) {
      flex-direction: column;
      gap: 0;
    }

    & .card-link{
      flex: 1 1;

      &__icon{
        @include breakpoint(mobile){
          display: none;
        }
      }
    }
  }
}
</style>