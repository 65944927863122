var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      attrs: { kind: "text", disabled: _vm.disabled },
      on: { click: _vm.clickHandler }
    },
    [
      _c(
        "svg",
        {
          staticClass: "z-icon z-fill",
          staticStyle: { width: "20px", height: "20px" },
          attrs: { version: "1.1", viewBox: "0 0 24 24" }
        },
        [
          _c("path", {
            attrs: {
              pid: "0",
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M12.6 3a.6.6 0 10-1.2 0v10.552L7.424 9.576a.6.6 0 10-.848.849l5 5a.6.6 0 00.848 0l5-5a.6.6 0 00-.848-.849L12.6 13.552V3zm-9 12a.6.6 0 10-1.2 0v4A2.6 2.6 0 005 21.6h14a2.6 2.6 0 002.6-2.6v-4a.6.6 0 10-1.2 0v4a1.4 1.4 0 01-1.4 1.4H5A1.4 1.4 0 013.6 19v-4z",
              fill: "#0077C8"
            }
          })
        ]
      ),
      _vm._v("\n    Скачать выбранные позиции\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6faaac59", { render: render, staticRenderFns: staticRenderFns })
  }
}