var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c("div", { staticClass: "unsubscribe-popup" }, [
        _c("div", {
          staticClass: "unsubscribe-popup__overlay",
          on: { click: _vm.closePopup }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "unsubscribe-popup__inner" }, [
          _c(
            "button",
            {
              staticClass: "unsubscribe-popup__close",
              on: { click: _vm.closePopup }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "z-icon z-fill",
                  staticStyle: { width: "24px", height: "24px" },
                  attrs: { version: "1.1", viewBox: "0 0 24 24" }
                },
                [
                  _c("path", {
                    attrs: {
                      pid: "0",
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M18.424 6.425a.6.6 0 10-.848-.849L12 11.152 6.424 5.576a.6.6 0 10-.848.849L11.15 12l-5.575 5.576a.6.6 0 00.848.849L12 12.849l5.576 5.576a.6.6 0 00.848-.849L12.848 12l5.576-5.575z",
                      fill: "#000000"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("h3", { staticClass: "unsubscribe-popup__title" }, [
            _vm._v("Сожалеем, что вы отписались от нашей рассылки")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "unsubscribe-popup__text" }, [
            _vm._v("Нам очень жаль расставаться с вами.")
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-33052d4c", { render: render, staticRenderFns: staticRenderFns })
  }
}