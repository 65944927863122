<template>
    <div class="z-checkbox-group" v-if="data.length">
        <div class="z-checkbox-group__list">
            <div
                :class="['z-checkbox z-checkbox-group__item', { 'is-error': error }]"
                v-for="(item, index) in data"
                :key="index"
            >
                <label
                    class="z-checkbox__label"
                    :class="{ 'z-checkbox__label--disabled': item.disabled }"
                >
                    <input
                        class="z-checkbox__input"
                        type="checkbox"
                        v-model="groupValue"
                        :value="item.value"
                        :disabled="item.disabled === 'undefined' ? false : item.disabled"
                        @change="onChange"
                    />
                    <span class="z-checkbox__box">
                        <svg version="1.1" viewBox="0 0 24 24" class="z-icon z-fill z-top" style="width: 22px; height: 22px;">
                            <path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.424 6.425a.6.6 0 10-.848-.849L9 16.152l-4.576-4.576a.6.6 0 00-.848.849l5 5a.6.6 0 00.848 0l11-11z" fill="#0077C8"></path>
                        </svg>
                    </span>
                    <slot name="text" class="z-checkbox__text"> </slot>
                </label>
            </div>
        </div>
        <span :class="['z-checkbox-group__error', errorClass]" v-if="error" v-html="error"></span>
    </div>
</template>

<script>
// localValue - для хранения значения группы, если компонент идет без привязки v-model
export default {
    name: 'z-checkbox',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        errorClass: {
            type: String,
            default: ''
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            localValue: this.value,
            error: '',
            text: {
                error: localize({
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field',
                    cn: '填项目'
                })
            }
        }
    },
    computed: {
        groupValue: {
            get () {
                if (this.value) return this.value // для работы без v-model
                return this.localValue
            },
            set (value) {
                this.$emit('input', value)
                this.localValue = value
            }
        }
    },
    methods: {
        onChange () {
            this.$nextTick(this.validate)
            this.$emit('change', this.groupValue)
        },
        validate () {
            if (this.required && this.groupValue.length === 0) {
                this.error = this.text.error
            } else {
                this.error = ''
            }
        }
    }
}
</script>

<style lang="scss">
$errorColor: #ca3d21;

.z-checkbox {
    $parent: &;

    font-size: 16px;
    display: flex;

    &__label {
        display: inline-flex;
        cursor: pointer;

        &:hover {
            #{$parent}__box {
                background-color: rgba($token-colors-primary, 0.2);
                border-color: rgba($token-colors-primary, 0.2);
            }

            #{$parent}__text {
                color: $token-colors-black-50;
            }
        }

        &--disabled {
            pointer-events: none;

            #{$parent}__text {
                color: $token-colors-black-20;
            }

            #{$parent}__box {
                border: 1px solid $token-colors-black-20;
            }
        }
    }

    &__text {
        font-weight: 400 !important;
        @include typo-level(XS);
    }

    &__box {
        display: block;
        width: 24px;
        height: 24px;
        background-color: $token-colors-white;
        border: 1px solid $token-colors-black-30;
        border-radius: 4px;
        transition: all 0.2s ease-in;
        flex-shrink: 0;
        cursor: pointer;
        @include margin-level(right, 2XS);

        .z-icon {
            display: none;
        }
    }

    &__input {
        display: none;

        &:checked {
            + #{$parent}__box {
                border-color: $token-colors-primary;

                .z-icon {
                    display: block;
                    path {
                        fill: $token-colors-primary !important;
                    }
                }
            }
        }
    }

    // error state
    &.is-error {
        #{$parent}__box {
            border-color: $token-colors-form-border-color-error;
        }
    }
}

.z-checkbox-group {
    &__list {
        margin-top: -1em;
    }

    &__item {
        margin-top: 1em;
    }

    &__error {
        font-size: 12px;
        color: $token-colors-form-border-color-error;
        margin-left: 20px;
    }
}
</style>
