var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._g(
      {
        tag: "component",
        staticClass: "z-button",
        class: _vm.classObject,
        attrs: { href: _vm.tag === "a" ? _vm.href : false }
      },
      _vm.$listeners
    ),
    [
      _vm.hasLeftSlot
        ? _c(
            "div",
            { staticClass: "z-button__icon z-button__icon--align-left" },
            [_vm._t("left")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.hasRightSlot
        ? _c(
            "div",
            { staticClass: "z-button__icon z-button__icon--align-right" },
            [_vm._t("right")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAppendSlot
        ? _c("div", { staticClass: "z-button__append" }, [_vm._t("append")], 2)
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ad1adc3e", { render: render, staticRenderFns: staticRenderFns })
  }
}