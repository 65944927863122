<template>
  <div
      class="sale-page-card__slider"
      :class="`js-image-slider-${id}`"
  >
    <div class="swiper-wrapper">
      <div
          v-for="image in images"
          class="swiper-slide"
          @click="onImgClick(image.link)"
      >
        <img :src="image.link" alt="">
      </div>
    </div>

    <div class="sale-page-card__slider-nav">
      <button
          class="sale-page-card__slider-button sale-page-card__slider-button-prev "
      ></button>
      <button
          class="sale-page-card__slider-button sale-page-card__slider-button-next"
      ></button>
    </div>
    <transition name="fade">
      <div
          v-if="isLightboxShown"
          class="sale-page-card__lightbox"
      >
        <div class="sale-page-card__lightbox-inner" @click="isLightboxShown = false">
          <svg version="1.1" viewBox="0 0 24 24" class="sale-page-card__lightbox-close z-icon z-fill" style="width: 30px; height: 30px;">
            <path pid="0" fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.424 6.425a.6.6 0 10-.848-.849L12 11.152 6.424 5.576a.6.6 0 10-.848.849L11.15 12l-5.575 5.576a.6.6 0 00.848.849L12 12.849l5.576 5.576a.6.6 0 00.848-.849L12.848 12l5.576-5.575z"
                  fill="#ffffff"></path>
          </svg>
          <img :src="imageSrc" alt="">
        </div>
        <div class="sale-page-card__lightbox-button --prev" @click="prevBtnClick" >
          <svg width="48" height="48" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.77652 10.4243C7.88904 10.5368 8.04165 10.6 8.20078 10.6C8.35991 10.6 8.51252 10.5368 8.62505 10.4243L12.625 6.42429C12.8594 6.18997 12.8594 5.81008 12.625 5.57576C12.3907 5.34145 12.0108 5.34145 11.7765 5.57576L8.20078 9.1515L4.62505 5.57576C4.39073 5.34145 4.01083 5.34145 3.77652 5.57576C3.5422 5.81007 3.5422 6.18997 3.77652 6.42429L7.77652 10.4243Z"
                  fill="#ECEEF3" />
          </svg>
        </div>
        <div class="sale-page-card__lightbox-button --next" @click="nextBtnClick" >
          <svg width="48" height="48" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.77652 10.4243C7.88904 10.5368 8.04165 10.6 8.20078 10.6C8.35991 10.6 8.51252 10.5368 8.62505 10.4243L12.625 6.42429C12.8594 6.18997 12.8594 5.81008 12.625 5.57576C12.3907 5.34145 12.0108 5.34145 11.7765 5.57576L8.20078 9.1515L4.62505 5.57576C4.39073 5.34145 4.01083 5.34145 3.77652 5.57576C3.5422 5.81007 3.5422 6.18997 3.77652 6.42429L7.77652 10.4243Z"
                  fill="#ECEEF3" />
          </svg>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Swiper from 'swiper'

export default {
  name: 'sale-card-slider',
  props: {
    images: {
      type: Array
    },
    id: String
  },
  data() {
    return {
      imageSrc: '',
      isLightboxShown: false,
      imageSlider: null,
    }
  },
  mounted() {
    this.imageSlider = new Swiper(`.js-image-slider-${this.id}`, {
      slidesPerView: 1,
      grabCursor: true,
      nextButton: '.sale-page-card__slider-button-next',
      prevButton: '.sale-page-card__slider-button-prev',
    });
  },
  methods: {
    onImgClick(src) {
      this.imageSrc = src
      this.isLightboxShown = true
    },
    nextBtnClick(){
      this.imageSlider.slideNext()
      const currentSlideIndex = this.imageSlider.activeIndex;
      this.imageSrc = this.imageSlider.slides[currentSlideIndex].querySelector('img').getAttribute('src');
    },
    prevBtnClick(){
      this.imageSlider.slidePrev()
      const currentSlideIndex = this.imageSlider.activeIndex;
      this.imageSrc = this.imageSlider.slides[currentSlideIndex].querySelector('img').getAttribute('src');
    }
  }
}
</script>

<style lang="scss">

.sale-page-card {
  &__slider {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 25%;
    width: 100%;
    height: fit-content;
    overflow: hidden;

    @include breakpoint(tablet) {
      max-width: 100%;
      width: auto;
    }

    & .swiper-wrapper {
      height: auto;
    }

    & .swiper-slide {
      position: relative;
      height: 0;
      width: 100%;
      padding-bottom: 75%;

      & img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
        object-position: 50% 50%;
        aspect-ratio: 4/3;
        pointer-events: none;
      }
    }


    &-button {
      position: absolute;
      top: 50%;
      left: 5px;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='51' height='51' viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='25.5' cy='25.5' r='25' transform='rotate(-180 25.5 25.5)' stroke='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.593 25.24a.698.698 0 0 0 .157.76l7.252 7.252a.7.7 0 0 0 .99-.99l-6.06-6.06h12.82a.7.7 0 1 0 0-1.4h-12.82l6.06-6.06a.7.7 0 1 0-.99-.99l-7.255 7.255a.7.7 0 0 0-.154.233z' fill='%23F8F9FB'/%3E%3C/svg%3E");
      background-size: 100%;
      background-color: transparent;
      transform: translate(0, -50%);
      z-index: 1;

      &-next {
        left: auto;
        right: 5px;
        transform: translate(0, -50%) rotate(180deg);
      }
    }
  }

  &__slider-nav {
    display: flex;
    justify-content: space-between;
  }

  &__lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000cc;
    z-index: 999;
    cursor: pointer;

    &-inner{
      max-width: 85%;
      max-height: 90%;
      width: 100%;
      height: 100%;
      display: flex;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &-close{
      position: absolute;
      top: 32px;
      right: 32px;
      z-index: 2;
    }

    &-button{
      position: absolute;
      top: 0;
      left: 0;
      width: 10%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.--prev{
        & svg{
          transform: rotate(90deg);
        }
      }
      &.--next{
        right: 0;
        left: auto;
        & svg{
          transform: rotate(-90deg);
        }
      }
    }
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>