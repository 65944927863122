<template>
    <div class="auth">
        <template v-if="state === AUTH_STATE.auth">
            <h1 class="auth__title" v-html="TEXT.title.auth" />
            <div>
                <span v-html="TEXT.description.hasNotAccout" />
                <span
                    class="link cursor-pointer"
                    @click="changeState(AUTH_STATE.reg)"
                    v-html="TEXT.links.reg"
                />
            </div>
            <AuthForm class="u-top-margin--m" />
            <div class="u-top-margin--s">
                <span v-html="TEXT.description.forgotPassword" />
                <span
                    class="link cursor-pointer"
                    @click="changeState(AUTH_STATE.restore)"
                    v-html="TEXT.links.restore"
                />
            </div>
        </template>

        <template v-if="state === AUTH_STATE.restore">
            <h1 class="auth__title" v-html="TEXT.title.restore" />
            <p>
                <span v-html="TEXT.description.alredyHasAccout" />
                <span
                    class="link cursor-pointer"
                    @click="changeState(AUTH_STATE.auth)"
                    v-html="TEXT.links.login"
                />
            </p>
            <RestoreForm />
        </template>

        <template v-if="state === AUTH_STATE.reg">
            <h1 class="auth__title" v-html="TEXT.title.reg" />
            <div>
                <span v-html="TEXT.description.alredyHasAccout" />
                <span
                    class="link cursor-pointer"
                    @click="changeState(AUTH_STATE.auth)"
                    v-html="TEXT.links.login"
                />
            </div>
            <RegistrationForm class="u-top-margin--m" />
        </template>

        <template v-if="state === AUTH_STATE.changePassword">
            <h1 class="auth__title" v-html="TEXT.title.changePassword" />
            
            <ChangePasswordForm class="u-top-margin--m" @change-state="changeState"/>
        </template>

        <template v-if="state === AUTH_STATE.confirmEmail">
            <h1 class="auth__title" v-html="TEXT.title.auth" />
            <p class="auth__success">
                <span v-html="TEXT.successMessage.emailConfirmed" />
                <span
                    class="link cursor-pointer"
                    @click="changeState(AUTH_STATE.auth)"
                    v-html="TEXT.links.login"
                />
            </p>
        </template>
    </div>
</template>

<script>
import AuthForm from './AuthForm.vue'
import RestoreForm from './RestoreForm.vue'
import RegistrationForm from './RegistrationForm.vue'
import ChangePasswordForm from './ChangePasswordForm.vue'
import parseGetParams from '../utils/parseGetParams'
import { AUTH_STATE, TEXT } from './const'

export default {
    name: 'auth-page',
    components: {
        AuthForm,
        RestoreForm,
        RegistrationForm,
        ChangePasswordForm
    },
    data () {
        return {
            AUTH_STATE,
            TEXT,
            state: AUTH_STATE.auth
        }
    },
    methods: {
        changeState (state, resetGetParams = true) {
            // remove get params
            if (resetGetParams) history.pushState({}, '', window.location.pathname)
            this.state = state
        },
        isChangePasswordState () {
            return window.location.search.includes('change_password')
        },
        init () {
            const getParams = parseGetParams()
            if (getParams.action === 'change_password') this.changeState('changePassword', false)
            if (getParams.action === 'confirm') this.changeState('confirmEmail', false)
        }
    },
    created () {
        this.init()
    }
}
</script>

<style lang="scss" scoped>
.auth {
    &__title {
        margin-bottom: 8px;
    }

    &__form {
        margin-top: 32px;
    }

    &__success {
        font-size: 18px;
        font-weight: 600;
    }
}
</style>