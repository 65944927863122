var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "form__fields" },
          [
            _c("ZInput", {
              ref: "email",
              staticClass: "form__field",
              attrs: {
                name: "email",
                placeholder: _vm.TEXT.fields.email,
                type: "email",
                required: "",
                "show-required-star": false
              },
              model: {
                value: _vm.formData.email,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "email", $$v)
                },
                expression: "formData.email"
              }
            }),
            _vm._v(" "),
            _c("ZInput", {
              ref: "password",
              staticClass: "form__field",
              attrs: {
                name: "password",
                placeholder: _vm.TEXT.fields.password,
                type: "password",
                required: "",
                "min-length": 8,
                "show-required-star": false
              },
              model: {
                value: _vm.formData.password,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "password", $$v)
                },
                expression: "formData.password"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.errorMessage
          ? _c("p", {
              staticClass: "form__error",
              domProps: { innerHTML: _vm._s(_vm.errorMessage) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form__footer" },
          [
            _c("ZButton", { attrs: { loading: _vm.isLoading } }, [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.TEXT.button.login) }
              })
            ])
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4260561b", { render: render, staticRenderFns: staticRenderFns })
  }
}